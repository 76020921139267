import {
    getPower,
    getSensiblePower,
    getWater,
    getEnthalpyFromTemperatureAndHumidity,
    getMoistureFromTemperatureAndHumidity,
} from '../components/DiagramContainer.functions';
import { createNewTab } from '../modules/Application/lib/tabs';
import { UNIT_ID } from './constants';

const getEmptyLine = labels => {
    return {
        values: {
            airflow: {
                id: UNIT_ID.pointAirflowToNext,
                label: labels.airflow,
                value: '',
                manuallyEntered: false,
            },
            power: {
                id: UNIT_ID.power,
                label: labels.power,
                value: '',
            },
            sensiblePower: {
                id: UNIT_ID.sensiblePower,
                label: labels.sensiblePower,
                value: '',
            },
            water: {
                id: UNIT_ID.water,
                label: labels.water,
                value: '',
            },
        },
    };
};

export const createTabData = (title, flowDataValues, pointsAndLines, season) => {
    return createNewTab(title, flowDataValues, pointsAndLines, true, season);
};

export const concatPointsAndLines = (first, second) => {
    return {
        points: [...(first.points ?? []), ...(second.points ?? [])],
        lines: [...(first.lines ?? []), ...(second.lines ?? [])].slice(0, -1), // remove last empty line
    };
};

export const emptyPointsAndLines = () => {
    return { points: [], lines: [] };
};

export const getPointsAndLines = (labels, pointData, flowDataValues) => {
    const points = pointData.map(data => {
        const { temperature, relativeHumidity, airflow } = data;
        if (temperature === undefined || relativeHumidity === undefined || airflow === undefined) {
            const displayMissingValues = {
                temperature: temperature ?? 'undefined',
                relativeHumidity: relativeHumidity ?? 'undefined',
                airflow: airflow ?? 'undefined',
            };
            throw new Error(
                `Point data for point: ${
                    data.name
                } is missing one or more result values: ${JSON.stringify(displayMissingValues)}`
            );
        }
        const enthalpy = getEnthalpyFromTemperatureAndHumidity(
            temperature,
            relativeHumidity,
            flowDataValues.atmPressure.value
        );
        const moistureContent = getMoistureFromTemperatureAndHumidity(
            temperature,
            relativeHumidity,
            flowDataValues.atmPressure.value
        );
        const pointValues = {
            pointTemperature: {
                label: labels.temperature,
                value: temperature,
                manuallyEntered: false,
                id: UNIT_ID.pointTemperature,
            },
            relativeHumidity: {
                label: labels.relativeHumidity,
                value: relativeHumidity,
                manuallyEntered: false,
                id: UNIT_ID.relativeHumidity,
            },
            moistureContent: {
                label: labels.moistureContent,
                value: moistureContent,
                manuallyEntered: false,
                id: UNIT_ID.moistureContent,
            },
            enthalpy: {
                label: labels.enthalpy,
                value: enthalpy,
                manuallyEntered: false,
                id: UNIT_ID.enthalpy,
            },
            pointAirflowToNext: {
                label: labels.airflow,
                value: airflow,
                manuallyEntered: false,
                id: UNIT_ID.pointAirflowToNext,
            },
        };

        const { name, lineToNext, prefix, color, id } = data;

        return {
            name: name,
            lineToNext: lineToNext,
            prefix: prefix,
            values: pointValues,
            color: color,
            id: id,
        };
    });

    const lines = points.map(point => {
        const nextPoint = points[points.indexOf(point) + 1];
        if (nextPoint === undefined) {
            return getEmptyLine(labels);
        }
        const power = getPower(
            point.values.pointAirflowToNext.value,
            flowDataValues.density.value,
            point.values.enthalpy.value,
            nextPoint.values.enthalpy.value
        );
        const sensiblePower = getSensiblePower(
            point.values.pointAirflowToNext.value,
            flowDataValues.density.value,
            point.values.moistureContent.value,
            nextPoint.values.moistureContent.value,
            power
        );
        const water = getWater(
            point.values.pointAirflowToNext.value,
            flowDataValues.density.value,
            point.values.moistureContent.value,
            nextPoint.values.moistureContent.value,
            power
        );
        const lineValues = {
            airflow: {
                id: UNIT_ID.pointAirflowToNext,
                label: labels.airflow,
                value: point.values.pointAirflowToNext.value,
                manuallyEntered: false,
            },
            power: {
                id: UNIT_ID.power,
                label: labels.power,
                value: power,
            },
            sensiblePower: {
                id: UNIT_ID.sensiblePower,
                label: labels.sensiblePower,
                value: sensiblePower,
            },
            water: {
                id: UNIT_ID.water,
                label: labels.water,
                value: water,
            },
        };
        return {
            values: lineValues,
        };
    });

    return { points, lines };
};

export const getFlowDataValues = (labels, temperature, atmPressure, airDensity) => {
    const flowDataValues = {
        globalTemperature: {
            id: UNIT_ID.globalTemperature,
            value: temperature,
            label: labels.temperature,
        },
        atmPressure: {
            id: UNIT_ID.atmPressure,
            value: atmPressure / 100, // mollier uses hPa instead of Pa
            label: labels.atmPressure,
        },
        density: {
            id: UNIT_ID.density,
            value: airDensity,
            label: labels.airDensity,
        },
    };
    return flowDataValues;
};

const getMixings = points => {
    let mixings = [];
    points.forEach(point => {
        const mixingAlreadyExists = mixings.find(m => m.from === point || m.to === point);
        if (mixingAlreadyExists) return;

        // Leakage can only occur within a component and never across different components.
        // Thus, by looking at the id, which comes from the component id in AHUD, we can filter out those points that comes from the same component.
        const pointsInSameComponent = points.filter(p => p.id === point.id);
        if (pointsInSameComponent.length !== 2) return;

        const internalAirflowDiffers =
            pointsInSameComponent[0].values.pointAirflowToNext.value !==
            pointsInSameComponent[1].values.pointAirflowToNext.value;

        // This code assumes that the first point never has mixing values.
        // In AHUD, the first point consists of the outdoor values (which is not a component).
        const index = points.indexOf(point);
        const isFirstPoint = index === 0;
        if (isFirstPoint) return;

        const currentAirflow = points[index].values.pointAirflowToNext.value;
        const previousAirflow = points[index - 1].values.pointAirflowToNext.value;
        const sameAirflowAsPrevious = currentAirflow === previousAirflow;
        const hasLeakage = internalAirflowDiffers && !sameAirflowAsPrevious;

        const componentWithHigherAirflow = pointsInSameComponent.reduce((prev, current) => {
            const currentAirflow = current.values.pointAirflowToNext.value;
            const previousAirflow = prev.values.pointAirflowToNext.value;
            return previousAirflow > currentAirflow ? prev : current;
        });
        const componentWithLowerAirflow = pointsInSameComponent.reduce((prev, current) => {
            const currentAirflow = current.values.pointAirflowToNext.value;
            const previousAirflow = prev.values.pointAirflowToNext.value;
            return previousAirflow < currentAirflow ? prev : current;
        });

        if (hasLeakage) {
            mixings.push({
                from: componentWithLowerAirflow,
                to: componentWithHigherAirflow,
            });
        }
    });

    return mixings;
};

export const calculateMixings = (points, flowDataValues) => {
    if (!points.length || !flowDataValues) return [];

    const mixings = getMixings(points);
    mixings.forEach(mixing => {
        const componentWithLowerAirflow = mixing.from;
        const componentWithHigherAirflow = mixing.to;
        const previousToHigherAirflow = points[points.indexOf(componentWithHigherAirflow) - 1];
        const previousToLowerAirflow = points[points.indexOf(componentWithLowerAirflow) - 1];

        // The difference in airflow for the component with higher airflow should be the same as the difference in
        // airflow for the component with lower airflow. Thus we only need to check one of them.
        const airflow = Math.abs(
            componentWithHigherAirflow.values.pointAirflowToNext.value -
                previousToHigherAirflow.values.pointAirflowToNext.value
        );
        const power = getPower(
            airflow,
            flowDataValues.density.value,
            componentWithHigherAirflow.values.enthalpy.value,
            previousToLowerAirflow.values.enthalpy.value
        );
        const sensiblePower = getSensiblePower(
            airflow,
            flowDataValues.density.value,
            componentWithHigherAirflow.values.moistureContent.value,
            previousToLowerAirflow.values.moistureContent.value,
            power
        );
        const water = getWater(
            airflow,
            flowDataValues.density.value,
            componentWithHigherAirflow.values.moistureContent.value,
            previousToLowerAirflow.values.moistureContent.value,
            power
        );

        mixing.values = {
            airflow: {
                id: UNIT_ID.pointAirflowToNext,
                label: 'quantities.airflow',
                value: airflow,
            },
            power: {
                id: UNIT_ID.power,
                label: 'quantities.power',
                value: power,
            },
            sensiblePower: {
                id: UNIT_ID.sensiblePower,
                label: 'quantities.sensiblePower',
                value: sensiblePower,
            },
            water: {
                id: UNIT_ID.water,
                label: 'quantities.water',
                value: water,
            },
        };
    });

    return [...mixings];
};

export const getNextPoint = (point, points) => {
    const currentIndex = points.indexOf(point);
    return points[currentIndex + 1];
};

export const hasMixing = (point, points) => {
    const nextPoint = getNextPoint(point, points);
    const mixings = getMixings(points);
    const foundMatch = mixings.filter(m => m.to === nextPoint).length === 1;
    return foundMatch;
};
