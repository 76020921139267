import React, { useEffect, useMemo, useContext } from 'react';
import { Container, DataColumn, ButtonContainer } from './DiagramContainer.styles';
import { Points } from './InputColumns/Points';
import { FlowData } from './InputColumns/FlowData';
import { Graph } from './Diagram/Graph';
import {
    getTemperatureFromMoistureAndEnthalpy,
    getTemperatureFromMoistureAndHumidity,
    getTemperatureFromHumidityAndEnthalpy,
    getHumidityFromTemperatureAndMoisture,
    getMoistureFromTemperatureAndHumidity,
    getMoistureFromTemperatureAndEnthalpy,
    getEnthalpyFromTemperatureAndHumidity,
    getEnthalpyFromMoistureAndHumidity,
    getDensity,
    getPower,
    getSensiblePower,
    getWater,
    getRelativeHumidity,
    getWetBulb
} from './DiagramContainer.functions';
import { AddPoint } from './InputColumns/AddPoint';
import { DragDropContext } from 'react-beautiful-dnd';
import { Context as ApplicationContext } from '../modules/Application/ApplicationContext';
import { Context as SettingsContext } from '../modules/Settings/SettingsContext';
import { Context as UnitConversionContext } from '../modules/Application/UnitConversionContext';
import { AIR_HUMIDITY, SEASONS, UNIT_ID, UNIT_SYSTEM, COLORS } from '../lib/constants';
import { isWetBulbUnit } from '../lib/utility/helpFunctions';
import { AHUD } from '@swegon-core/ui-components';
import { calculateMixings } from '../lib/dataFunctions';

export const DiagramContainer = () => {
    const {
        pointsAndLines,
        setPointsAndLines,
        pointsRef,
        lineToNext,
        setLineToNext,
        airflowToNext,
        setAirflowToNext,
        isAddPointVisible,
        setVisible,
        name,
        setName,
        isEditing,
        setIsEditing,
        flowDataValues,
        setFlowDataValues,
        visualFlowDataValues,
        setVisualFlowDataValues,
        flowDataValuesRef,
        pointValues,
        setPointValues,
        setShouldStore,
        visualPointValues,
        setVisualPointValues,
        season,
        setSeason
    } = useContext(ApplicationContext);
    const { isExternalApplication, typeOfUnitSystem, typeOfHumidity } = useContext(SettingsContext);
    const { getConversion } = useContext(UnitConversionContext);
    const { RadioButton, Sun, Snowflake } = AHUD;

    const points = useMemo(() => pointsAndLines.points || [], [pointsAndLines]);
    const lines = useMemo(() => pointsAndLines.lines || [], [pointsAndLines]);
    const mixings = useMemo(() => {
        if (isExternalApplication) {
            return [];
        } else {
            return calculateMixings(points, flowDataValues) || [];
        }
    }, [pointsAndLines]);

    const updateValues = target => {
        if (!target) {
            return;
        }

        const id = target.id;
        const value = target.type === 'number' ? target.valueAsNumber : target.value;

        if (id === 'name') {
            setName(target.value);
        }

        if (id === 'lineToNext') {
            setLineToNext(!lineToNext);
        }

        if (typeOfUnitSystem === UNIT_SYSTEM.METRIC) {
            if (Object.keys(flowDataValues).includes(id)) {
                setFlowDataValues({
                    ...flowDataValues,
                    [id]: {
                        ...flowDataValues[id],
                        value: value
                    }
                });
            }
            if (Object.keys(pointValues).includes(id)) {
                setPointValues({
                    ...pointValues,
                    [id]: {
                        ...pointValues[id],
                        value: value,
                        manuallyEntered: value || value === 0 ? true : false
                    }
                });
            }
        }

        // If imperial units are selected, we convert the values back to SI units
        else {
            if (Object.keys(flowDataValues).includes(id)) {
                setFlowDataValues({
                    ...flowDataValues,
                    [id]: {
                        ...flowDataValues[id],
                        value: getConversion(value, id, true, false)
                    }
                });
            }
            if (Object.keys(pointValues).includes(id)) {
                setPointValues({
                    ...pointValues,
                    [id]: {
                        ...pointValues[id],
                        value: getConversion(value, id, true, false),
                        manuallyEntered: value || value === 0 ? true : false
                    }
                });
            }
        }

        // If wet bulb is selected, convert back to relative humidity
        if (isWetBulbUnit(id, typeOfHumidity)) {
            const dryTemperature = pointValues.pointTemperature.value;
            const atmPressure = flowDataValues.atmPressure.value;
            // Make sure the wet-bulb temperature is in Celsius
            const wetTemperature =
                typeOfUnitSystem === UNIT_SYSTEM.IMPERIAL
                    ? getConversion(value, UNIT_ID.pointTemperature, true)
                    : value;

            const relativeHumidity = getRelativeHumidity(
                dryTemperature,
                wetTemperature,
                atmPressure
            );

            setPointValues({
                ...pointValues,
                relativeHumidity: {
                    ...pointValues.relativeHumidity,
                    value: getConversion(relativeHumidity, id, true),
                    manuallyEntered: value || value === 0 ? true : false
                }
            });
        }
    };

    const onEnter = target => {
        if (typeof window === "undefined") {
            return;
        };
        updateValues(target);

        if (target == document.activeElement) {
            document.activeElement.blur();
        }

        // TODO: Add input validation
    };

    const onBlur = target => {
        updateValues(target);

        // TODO: Add input validation
    };

    const getPrefix = point => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
        const startingNumber = 1;
        let letterIndex = 0;
        let number = 1;

        const index = points.indexOf(point);
        for (let i = 0; i < index; i++) {
            const lineToNext = points[i].lineToNext;
            letterIndex += lineToNext ? 0 : 1;
            number = lineToNext ? number + 1 : startingNumber;

            // If we run out of letters, start from the beginning
            if (letterIndex >= letters.length) {
                letterIndex = 0;
            }
        }

        const letter = letters[letterIndex];
        return `${letter}${number}`;
    };

    const getColor = point => {
        const colors = [
            COLORS.green,
            COLORS.lavender,
            COLORS.pink,
            COLORS.red,
            COLORS.yellow,
            COLORS.purple,
            COLORS.orange
        ];
        let colorIndex = 0;

        const index = points.indexOf(point);
        for (let i = 0; i < index; i++) {
            const lineToNext = points[i].lineToNext;
            colorIndex += lineToNext ? 0 : 1;

            // If we run out of colors, start from the beginning
            if (colorIndex >= colors.length) {
                colorIndex = 0;
            }
        }

        const color = colors[colorIndex];
        return color;
    };

    const addPoint = target => {
        const { name, pointValues, lineToNext } = target;
        const values = JSON.parse(JSON.stringify(pointValues));

        const point = {
            name: name,
            values: values,
            lineToNext,
            isActive: false,
            prefix: ''
        };

        const newPoints = [...points, point];
        const newLines = points.length >= 1 ? addLine() : lines;

        setAirflowToNext(values.pointAirflowToNext);
        setPointsAndLines({ points: newPoints, lines: calculateLineValues(newPoints, newLines) });
        setShouldStore(true);
    };

    const deletePoint = index => {
        const newPoints = [...points];
        const newLines = [...lines];
        newPoints.splice(index, 1);
        newLines.splice(index - 1, 1);
        setPointsAndLines({
            points: [...newPoints],
            lines: calculateLineValues(newPoints, newLines)
        });
        setShouldStore(true);
    };

    const editPoint = () => {
        const [point] = points.filter(point => point.isActive);

        const newPoint = {
            ...point,
            name: name,
            values: { ...pointValues },
            lineToNext: lineToNext
        };

        const index = points.indexOf(point);
        const newPoints = [...points];
        newPoints.splice(index, 1, newPoint);
        setPointsAndLines({ points: [...newPoints], lines: calculateLineValues(newPoints, lines) });
        setShouldStore(true);
    };

    const addLine = () => {
        const line = {
            values: {
                airflow: {
                    id: UNIT_ID.pointAirflowToNext,
                    label: 'quantities.airflow'
                },
                power: {
                    id: UNIT_ID.power,
                    label: 'quantities.power'
                },
                sensiblePower: {
                    id: UNIT_ID.sensiblePower,
                    label: 'quantities.sensiblePower'
                },
                water: {
                    id: UNIT_ID.water,
                    label: 'quantities.water'
                }
            }
        };
        return [...lines, line];
    };

    const clearValues = () => {
        const actualValues = JSON.parse(JSON.stringify(pointValues));
        const visualValues = JSON.parse(JSON.stringify(visualPointValues));
        for (const [key, values] of Object.entries(actualValues)) {
            if (!values.manuallyEntered && key !== 'pointAirflowToNext') {
                actualValues[key].value = '';
                visualValues[key].value = '';
            }
        }

        setPointValues({
            ...actualValues
        });
        setVisualPointValues({
            ...visualValues
        });
    };

    const transferValues = point => {
        const enteredValues = Object.entries(point.values).filter(
            ([key, values]) => key !== 'pointAirflowToNext' && values.manuallyEntered
        );

        if (!enteredValues.length) {
            point.values.pointTemperature.manuallyEntered = true;
            point.values.relativeHumidity.manuallyEntered = true;
        }

        setPointValues({ ...point.values });

        const humidity =
            typeOfHumidity === AIR_HUMIDITY.WET_BULB
                ? getWetBulb(
                      point.values.pointTemperature.value,
                      point.values.relativeHumidity.value,
                      flowDataValues.atmPressure.value
                  )
                : point.values.relativeHumidity.value;
        const propertyName =
            typeOfHumidity === AIR_HUMIDITY.WET_BULB
                ? UNIT_ID.pointTemperature
                : UNIT_ID.relativeHumidity;

        setVisualPointValues({
            ...visualPointValues,
            pointTemperature: {
                ...visualPointValues.pointTemperature,
                value: getConversion(
                    point.values.pointTemperature.value,
                    point.values.pointTemperature.id
                )
            },
            relativeHumidity: {
                ...visualPointValues.relativeHumidity,
                value: getConversion(humidity, propertyName)
            },
            moistureContent: {
                ...visualPointValues.moistureContent,
                value: getConversion(
                    point.values.moistureContent.value,
                    point.values.moistureContent.id
                )
            },
            enthalpy: {
                ...visualPointValues.enthalpy,
                value: getConversion(point.values.enthalpy.value, point.values.enthalpy.id)
            },
            pointAirflowToNext: {
                ...visualPointValues.pointAirflowToNext,
                value: getConversion(point.values.pointAirflowToNext.value, point.values.pointAirflowToNext.id)
            }
        });
        setName(point.name);
        setLineToNext(point.lineToNext);
        point.isActive = true;
    };

    const toggleAddPoint = (point = undefined) => {
        if (!isExternalApplication) {
            return;
        }
        setVisible(!isAddPointVisible);

        if (point?.values) {
            setIsEditing(true);
            transferValues(point);
        } else {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (!isEditing) {
            points.forEach(point => {
                point.isActive = false;
            });
        }
    }, [isEditing]);

    const clearName = () => {
        setName('');
    };

    const calculatePointValues = values => {
        const atmPressure = flowDataValues.atmPressure.value;
        var pointTemperature = values.pointTemperature.value;
        var relativeHumidity = values.relativeHumidity.value;
        var moistureContent = values.moistureContent.value;
        var enthalpy = values.enthalpy.value;

        if (values.pointTemperature.manuallyEntered && values.relativeHumidity.manuallyEntered) {
            moistureContent = getMoistureFromTemperatureAndHumidity(
                pointTemperature,
                relativeHumidity,
                atmPressure
            );
            enthalpy = getEnthalpyFromTemperatureAndHumidity(
                pointTemperature,
                relativeHumidity,
                atmPressure
            );
        }
        if (values.pointTemperature.manuallyEntered && values.moistureContent.manuallyEntered) {
            relativeHumidity = getHumidityFromTemperatureAndMoisture(
                pointTemperature,
                moistureContent,
                atmPressure
            );

            enthalpy = getEnthalpyFromTemperatureAndHumidity(
                pointTemperature,
                relativeHumidity,
                atmPressure
            );
        }
        if (values.pointTemperature.manuallyEntered && values.enthalpy.manuallyEntered) {
            relativeHumidity = getHumidityFromTemperatureAndMoisture(
                pointTemperature,
                moistureContent,
                atmPressure
            );
            moistureContent = getMoistureFromTemperatureAndEnthalpy(pointTemperature, enthalpy);
        }
        if (values.relativeHumidity.manuallyEntered && values.moistureContent.manuallyEntered) {
            pointTemperature = getTemperatureFromMoistureAndHumidity(
                moistureContent,
                relativeHumidity,
                atmPressure
            );
            enthalpy = getEnthalpyFromMoistureAndHumidity(
                moistureContent,
                relativeHumidity,
                atmPressure
            );
        }
        if (values.relativeHumidity.manuallyEntered && values.enthalpy.manuallyEntered) {
            (pointTemperature = getTemperatureFromHumidityAndEnthalpy(
                relativeHumidity,
                enthalpy,
                atmPressure
            )),
                (moistureContent = getMoistureFromTemperatureAndEnthalpy(
                    pointTemperature,
                    enthalpy
                ));
        }
        if (values.moistureContent.manuallyEntered && values.enthalpy.manuallyEntered) {
            pointTemperature = getTemperatureFromMoistureAndEnthalpy(moistureContent, enthalpy);
            relativeHumidity = getHumidityFromTemperatureAndMoisture(
                pointTemperature,
                moistureContent,
                atmPressure
            );
        }

        const newValues = {
            ...values,
            pointTemperature: {
                ...values.pointTemperature,
                value: pointTemperature
            },
            relativeHumidity: {
                ...values.relativeHumidity,
                value: relativeHumidity
            },
            moistureContent: {
                ...values.moistureContent,
                value: moistureContent
            },
            enthalpy: {
                ...values.enthalpy,
                value: enthalpy
            }
        };

        return newValues;
    };

    const updatePointValues = () => {
        const newValues = calculatePointValues(pointValues);
        setPointValues({
            ...pointValues,
            ...newValues
        });

        // We also need to update the visible values in the input fields
        const newVisualValues = JSON.parse(JSON.stringify(visualPointValues));
        for (const [key, values] of Object.entries(newVisualValues)) {
            if (!pointValues[key].manuallyEntered && key !== 'pointAirflowToNext') {
                newVisualValues[key].value = getConversion(
                    pointValues[key].value,
                    pointValues[key].id
                );
            }
        }

        setVisualPointValues({
            ...newVisualValues
        });
    };

    const updateAllPointsValues = points => {
        const newPoints = [...points];
        newPoints.forEach(point => {
            point.values = calculatePointValues(point.values);
        });

        return [...newPoints];
    };

    const calculateLineValues = (fromPoints, fromLines) => {
        const newLines = [...fromLines];
        const defaultAirflow = 1;
        newLines.forEach(line => {
            const index = newLines.indexOf(line);
            const firstPoint = fromPoints[index];
            const secondPoint = fromPoints[index + 1];

            const power = getPower(
                firstPoint.values.pointAirflowToNext?.value || defaultAirflow,
                flowDataValues.density.value,
                firstPoint.values.enthalpy.value,
                secondPoint.values.enthalpy.value
            );
            const sensiblePower = getSensiblePower(
                firstPoint.values.pointAirflowToNext?.value || defaultAirflow,
                flowDataValues.density.value,
                firstPoint.values.moistureContent.value,
                secondPoint.values.moistureContent.value,
                power
            );
            const water = getWater(
                firstPoint.values.pointAirflowToNext?.value || defaultAirflow,
                flowDataValues.density.value,
                firstPoint.values.moistureContent.value,
                secondPoint.values.moistureContent.value,
                power
            );

            line.values = {
                airflow: {
                    ...line.values.airflow,
                    value: firstPoint.values.pointAirflowToNext?.value || defaultAirflow,
                    manuallyEntered: firstPoint.values.pointAirflowToNext?.manuallyEntered || false
                },
                power: {
                    ...line.values.power,
                    value: power
                },
                sensiblePower: {
                    ...line.values.sensiblePower,
                    value: sensiblePower
                },
                water: {
                    ...line.values.water,
                    value: water
                }
            };
        });
        return [...newLines];
    };

    const onDragEnd = result => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const newPoints = [...points];
        newPoints.splice(source.index, 1);
        newPoints.splice(destination.index, 0, points[source.index]);
        setPointsAndLines({ points: [...newPoints], lines: calculateLineValues(newPoints, lines) });
        setShouldStore(true);
    };

    useEffect(() => {
        if (!points || !isExternalApplication) {
            return;
        }

        if (JSON.stringify(points) === JSON.stringify(pointsRef.current.points)) {
            return;
        }

        const newPoints = [...points];
        newPoints.forEach(point => {
            point.prefix = getPrefix(point);
            point.color = getColor(point);
        });
        setPointsAndLines({ points: [...newPoints], lines: lines });
        pointsRef.current.points = points;
        setShouldStore(true);
    }, [points]);

    useEffect(() => {
        if (!flowDataValues) {
            return;
        }

        if (JSON.stringify(flowDataValues) === JSON.stringify(flowDataValuesRef.current)) {
            return;
        }

        const temperature = flowDataValues.globalTemperature.value;
        const atmPressure = flowDataValues.atmPressure.value;
        const newDensity = getDensity(temperature, atmPressure);

        setFlowDataValues({
            ...flowDataValues,
            density: {
                ...flowDataValues.density,
                value: newDensity
            }
        });
        setVisualFlowDataValues({
            ...visualFlowDataValues,
            density: {
                ...visualFlowDataValues.density,
                value: getConversion(newDensity, visualFlowDataValues.density.id)
            }
        });

        flowDataValuesRef.current = flowDataValues;
        setShouldStore(true);
    }, [flowDataValues?.globalTemperature, flowDataValues?.atmPressure]);

    useEffect(() => {
        if (!pointValues) {
            return;
        }

        const enteredValues = Object.entries(pointValues).filter(
            ([key, values]) => key !== 'pointAirflowToNext' && values.manuallyEntered
        );
        if (enteredValues.length === 2) {
            updatePointValues();
        } else {
            clearValues();
        }
    }, [
        pointValues?.pointTemperature.value,
        pointValues?.relativeHumidity.value,
        pointValues?.moistureContent.value,
        pointValues?.enthalpy.value
    ]);

    useEffect(() => {
        if (points && points.length) {
            setPointsAndLines({
                points: updateAllPointsValues(points),
                lines: calculateLineValues(points, lines)
            });
        }
    }, [flowDataValues]);

    return (
        (!flowDataValues && <></>) ||
        (flowDataValues && (
            <Container>
                {!isExternalApplication && (
                    <ButtonContainer>
                        <RadioButton
                            items={[
                                {
                                    value: SEASONS.SUMMER,
                                    icon: <Sun />,
                                    label: 'SUMMER'
                                },
                                {
                                    value: SEASONS.WINTER,
                                    icon: <Snowflake />,
                                    label: 'WINTER'
                                }
                            ]}
                            value={season}
                            onChange={value => {
                                setSeason(value);
                            }}
                            testid='seasons'
                        />
                    </ButtonContainer>
                )}
                <Graph
                    flowDataValues={flowDataValues}
                    points={points}
                    mixings={mixings}
                    usePrintSize={false}
                />
                <DataColumn>
                    {isExternalApplication && (
                        <FlowData
                            values={flowDataValues}
                            onBlur={onBlur}
                            onEnter={onEnter}
                            typeOfUnitSystem={typeOfUnitSystem}
                        />
                    )}
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Points
                            points={points}
                            lines={lines}
                            mixings={mixings}
                            toggleAddPoint={toggleAddPoint}
                            deletePoint={deletePoint}
                        />
                    </DragDropContext>
                </DataColumn>
                <AddPoint
                    name={name}
                    values={pointValues}
                    lineToNext={lineToNext}
                    airflowToNext={airflowToNext}
                    onChange={updateValues}
                    onClick={() => {
                        isEditing ? editPoint() : addPoint({ name, pointValues, lineToNext });
                        clearName();
                    }}
                    close={toggleAddPoint}
                    isVisible={isAddPointVisible}
                    isEditing={isEditing}
                />
            </Container>
        ))
    );
};
