import styled from 'styled-components';
import { WHITE } from '@swegon-core/ui-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 0.625rem 1fr 2.5rem;
    grid-template-rows: 2rem auto;
    align-items: center;
    padding: 0 0.625rem 0.75rem 0.625rem;
    position: relative;
    background-color: ${WHITE};

    &:active {
        box-shadow: ${props => (props.isDragging ? '0px 2px 6px -2px #d1d2d2' : '')};
    }
`;
export const Circle = styled.div`
    width: 0.625rem;
    height: 0.625rem;
    background-color: ${props => (props.color ? props.color : '')};
    border-radius: 50%;
`;
export const Title = styled.div`
    display: flex;
    align-items: center;
    padding-top: 2px;
`;
export const Prefix = styled.div`
    font-size: 0.875rem;
    padding-left: 0.625rem;
`;
export const Label = styled.div`
    font-size: 0.875rem;
    inline-size: ${props => `${props.width}px`};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 0.625rem;
    box-sizing: border-box;
`;
export const Options = styled.div`
    grid-column: 3;
    grid-row: 1;
    visibility: ${props => (props.hide ? `hidden` : `visible`)};
`;
export const Handle = styled.div`
    grid-column: 3;
    grid-row: 2 / span 100;
    visibility: ${props => (props.hide ? `hidden` : `visible`)};
`;
export const Data = styled.div`
    grid-column: 2;
    grid-row: 2;
    margin-top: -8px;
    padding-left: 0.625rem;
`;
export const OverlayContainer = styled.div`
    position: absolute;
    right: 0;
    top: 2.5rem;
    z-index: 2;
`;
