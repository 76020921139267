import styled from 'styled-components';
import { SOG_GRAY_3 } from '@swegon-core/ui-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
`;

export const DropdownContainer = styled.div`
    margin-top: 0.6rem;
    display: grid;
    gap: 1rem;
`;

export const PillsContainer = styled.div`
    display: grid;
    gap: 1rem;
`;

export const InputContainer = styled.div`
    display: grid;
    grid-template-rows: 1.875rem 4rem 4rem;
    grid-column-gap: 1rem;
`;

export const Label = styled.p`
    font-size: 0.875rem;
    grid-column: 1 / span 2;
    margin: 0;
    color: ${SOG_GRAY_3};
`;
