const patchFlowData = tab => {
    // Add new property 'id'
    tab.content.flowDataValues = {
        ...tab.content.flowDataValues,
        globalTemperature: {
            ...tab.content.flowDataValues.globalTemperature,
            id: 'globalTemperature'
        },
        atmPressure: {
            ...tab.content.flowDataValues.atmPressure,
            id: 'atmPressure',
            label: 'quantities.atmPressure'
        },
        density: {
            ...tab.content.flowDataValues.density,
            id: 'density'
        }
    };

    // Remove old property 'unit'
    Object.values(tab.content.flowDataValues).forEach(values => {
        delete values.unit;
    });
};

const patchPointData = tab => {
    // Add new property 'id'
    tab.content.pointValues = {
        ...tab.content.pointValues,
        pointTemperature: {
            ...tab.content.pointValues.pointTemperature,
            id: 'pointTemperature'
        },
        relativeHumidity: {
            ...tab.content.pointValues.relativeHumidity,
            id: 'relativeHumidity'
        },
        moistureContent: {
            ...tab.content.pointValues.moistureContent,
            id: 'moistureContent'
        },
        enthalpy: {
            ...tab.content.pointValues.enthalpy,
            id: 'enthalpy'
        },
        pointAirflowToNext: {
            ...tab.content.pointValues.pointAirflowToNext,
            id: 'pointAirflowToNext'
        }
    };

    // Remove old property 'unit'
    Object.values(tab.content.pointValues).forEach(values => {
        delete values.unit;
    });
};

const patchPoints = tab => {
    // Add new property 'id'
    const points = tab.content.points;
    points.forEach(point => {
        point.values = {
            ...point.values,
            pointTemperature: {
                ...point.values.pointTemperature,
                id: 'pointTemperature'
            },
            relativeHumidity: {
                ...point.values.relativeHumidity,
                id: 'relativeHumidity'
            },
            moistureContent: {
                ...point.values.moistureContent,
                id: 'moistureContent'
            },
            enthalpy: {
                ...point.values.enthalpy,
                id: 'enthalpy'
            },
            pointAirflowToNext: {
                ...point.values.pointAirflowToNext,
                id: 'pointAirflowToNext'
            }
        };
    });

    // Remove old property 'unit'
    points.forEach(point => {
        const pointValues = point.values;
        Object.values(pointValues).forEach(values => {
            delete values.unit;
        });
    });
};

const patchLines = tab => {
    // Add new property 'id'
    const lines = tab.content.lines;
    lines.forEach(line => {
        line.values = {
            ...line.values,
            airflow: {
                ...line.values.airflow,
                id: 'pointAirflowToNext'
            },
            power: {
                ...line.values.power,
                id: 'linePower'
            },
            sensiblePower: {
                ...line.values.sensiblePower,
                id: 'sensiblePower'
            },
            water: {
                ...line.values.water,
                id: 'water'
            }
        };
    });

    // Remove old property 'unit'
    lines.forEach(line => {
        const lineValues = line.values;
        Object.values(lineValues).forEach(values => {
            delete values.unit;
        });
    });
};

export const patchData = (tab, version) => {
    patchFlowData(tab);
    patchPointData(tab);
    patchPoints(tab);
    patchLines(tab);

    return tab;
};
