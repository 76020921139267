import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 58% 22% 20%;
`;
export const Label = styled.p`
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.125rem 0.625rem;
    margin: 0;
    font-weight: ${props => (props.manuallyEntered ? 'bold' : 'normal')};
`;
export const Value = styled(Label)``;
export const Unit = styled(Label)``;
