import { compareVersions, APPLICATION_VERSION } from '../../../lib/version';
import { patchData } from './migrations/flowAndPointData';
import { addTabTypeMigration } from './migrations/tabTypeMigration';

// Place all migrations here in increasing version order
// Migrations should be defined as an array of [version, function, name]
// Migration functions should have two parameters (tab, version)
//   and return the updated tab
// Do not use 'migration' in name
const MIGRATIONS = [
    // e.g. ["0.2.0", migrateVersion, "Version"],
    ['1.0.2', addTabTypeMigration, 'Patch tabType and isDraggable'],
    ['1.1.15', patchData, 'Patch flow data']
];

export const migrateTabs = (tabs, version = APPLICATION_VERSION, migrations = MIGRATIONS) => {
    if (!tabs) {
        return [];
    }

    // Only apply migrations that are for this application version or older
    const validMigrations = migrations.filter(([v]) => compareVersions(v, version) <= 0);

    return tabs.map(tab => {
        var migrated = validMigrations
            .filter(([v]) => compareVersions(tab.version, v) < 0)
            .reduce((tab, [_, migration, name]) => {
                console.log(`Apply migration ${name} for tab (version ${tab.version})`);
                return migration(tab, version);
            }, tab);

        return migrateVersion(migrated, version);
    });
};

export const migrateVersion = (tab, version) => {
    if (tab && compareVersions(tab.version, version) < 0) {
        console.log(`Migrating tab '${tab.title}' from version ${tab.version} to ${version}`);
        tab.version = version;
    }

    return tab;
};
