import React, { useContext } from 'react';
import {
    Header,
    LogoContainer,
    DataTablesContainer,
    PrintOverlay,
    Table,
    TableHeader,
    TableDataRow,
    TableData,
    EmptyTableRow,
    PrintOverlayLandscape,
    PrintOverlayPortrait
} from './Print.styles';
// import { AHUD } from '@swegon-core/ui-components';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Graph } from '../Diagram/Graph';
import { getLineData, previousPointHasLineData } from './Print.functions';
import { Context as ApplicationContext } from '../../modules/Application/ApplicationContext';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext';
import { AIR_HUMIDITY, UNIT_ID } from '../../lib/constants';
import { Context as SettingsContext } from '../../modules/Settings/SettingsContext';
import { getWetBulb } from '../DiagramContainer.functions';
import { getFormattedValue } from '../../lib/utility/helpFunctions';
import { isWetBulbUnit } from '../../lib/utility/helpFunctions';
import { processTableHeader } from './ProcessTableHeader';

export const Print = ({ isVisible }) => {
    const { pointsAndLines, flowDataValues } = useContext(ApplicationContext);
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);
    const { typeOfHumidity } = useContext(SettingsContext);
    const printOrientation =
        typeof window !== 'undefined' ? localStorage.getItem('print_orientation') : 'portrait';
    const { points, lines } = pointsAndLines;
    const { l } = useContext(TranslationContext);
    // const { SwegonLogo } = AHUD;



    const getLineDataForRow = (point) => {
        const lineData = getLineData(point, lines, points);
        const lineTableData = Object.entries(lineData.values).map(([key, values]) => {
            return (
                <TableData key={key}>
                    {getFormattedValue(getConversion(values.value, values.id))}
                </TableData>
            );
        });

        return lineTableData;
    }

    const getHumidityText = () => {
        if (typeOfHumidity === AIR_HUMIDITY.WET_BULB) {
            const label = l('quantities.wetBulb');
            const abbreviation = getAbbreviation(UNIT_ID.pointTemperature);
            return `${label} ${abbreviation}`;
        } else {
            const label = l('quantities.relativeHumidity');
            const abbreviation = getAbbreviation(UNIT_ID.relativeHumidity);
            return `${label} ${abbreviation}`;
        }
    };

    const getValue = (point, values) => {
        const { value, id } = values;

        if (isWetBulbUnit(id, typeOfHumidity)) {
            const temperature = point.values.pointTemperature.value;
            const pressure = flowDataValues.atmPressure.value;
            const wetBulb = getWetBulb(temperature, value, pressure);
            return getFormattedValue(getConversion(wetBulb, UNIT_ID.pointTemperature));
        } else {
            return getFormattedValue(getConversion(value, id));
        }
    };

    return (
        <PrintOverlay>
            {printOrientation === 'landscape' ? (
                <PrintOverlayLandscape />
            ) : (
                <PrintOverlayPortrait />
            )}
            <DataTablesContainer>
                <Header>{'Swegon Mollier Diagram'}</Header>
                {/* <LogoContainer>
                    <SwegonLogo width={200} height={100} />
                </LogoContainer> */}
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>{l('flowData.title')}</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {flowDataValues &&
                            Object.entries(flowDataValues).map(([key, values]) => {
                                return (
                                    <tr key={key}>
                                        <TableData>{l(values.label)}</TableData>
                                        <TableData>
                                            {getFormattedValue(
                                                getConversion(values.value, values.id)
                                            )}
                                        </TableData>
                                        <TableData>{getAbbreviation(values.id)}</TableData>
                                    </tr>
                                );
                            })}
                    </tbody>
                </Table>
                <Table>
                    <thead>
                        <tr>
                            <TableHeader>{l('print.process')}</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        <TableDataRow>
                            <TableData key={processTableHeader.prefix}> {l(processTableHeader.prefix)} </TableData>
                            <TableData key={processTableHeader.name}> {l(processTableHeader.name)} </TableData>
                            <TableData key={processTableHeader.temperature}>
                                {`${l(processTableHeader.temperature)} ${getAbbreviation(
                                    UNIT_ID.globalTemperature
                                )}`}
                            </TableData>
                            <TableData key={processTableHeader.relativeHumidity}>
                                {getHumidityText()}
                            </TableData>
                            <TableData key={processTableHeader.moistureContent}>
                                {`${l(processTableHeader.moistureContent)} ${getAbbreviation(
                                    UNIT_ID.moistureContent
                                )}`}
                            </TableData>
                            <TableData key={processTableHeader.enthalpy}>
                                {`${l(processTableHeader.enthalpy)} ${getAbbreviation(UNIT_ID.enthalpy)}`}
                            </TableData>
                            <TableData key={processTableHeader.airflow}>
                                {`${l(processTableHeader.airflow)} ${getAbbreviation(
                                    UNIT_ID.pointAirflowToNext
                                )}`}
                            </TableData>
                            <TableData key={processTableHeader.power}>
                                {`${l(processTableHeader.power)} ${getAbbreviation(UNIT_ID.power)}`}
                            </TableData>
                            <TableData key={processTableHeader.sensiblePower}>
                                {`${l(processTableHeader.sensiblePower)} ${getAbbreviation(
                                    UNIT_ID.sensiblePower
                                )}`}
                            </TableData>
                            <TableData key={processTableHeader.water}>
                                {`${l(processTableHeader.water)} ${getAbbreviation(UNIT_ID.water)}`}
                            </TableData>
                        </TableDataRow>
                        {points &&
                            Object.entries(points).map(([key, point]) => {
                                return point.lineToNext ? (
                                    <tr key={key}>
                                        <TableData key={key}> {point.prefix} </TableData>
                                        <TableData key={point.name}> {point.name} </TableData>
                                        {Object.entries(point.values).map(([key, values]) => {
                                            return (
                                                key !== 'pointAirflowToNext' && (
                                                    <TableData key={key}>
                                                        {getValue(point, values)}
                                                    </TableData>
                                                )
                                            );
                                        })}
                                        {previousPointHasLineData(points, point)
                                            ? getLineDataForRow(point, points, lines)
                                            : null}
                                    </tr>
                                ) : (
                                    <React.Fragment key={`${key}fragment`}>
                                        <tr key={key}>
                                            <TableData key={key}> {point.prefix} </TableData>
                                            <TableData key={point.name}> {point.name} </TableData>
                                            {Object.entries(point.values).map(([key, values]) => {
                                                return (
                                                    key !== 'pointAirflowToNext' && (
                                                        <TableData key={key}>
                                                            {getFormattedValue(
                                                                getConversion(
                                                                    values.value,
                                                                    values.id
                                                                )
                                                            )}
                                                        </TableData>
                                                    )
                                                );
                                            })}
                                            {previousPointHasLineData(points, point)
                                                ? getLineDataForRow(point, points, lines)
                                                : null}
                                        </tr>
                                        <EmptyTableRow key={`${key}emptyRow`}>
                                            <TableData key={`${key}emptyData1`} colSpan='10'>
                                                {' '}
                                            </TableData>
                                        </EmptyTableRow>
                                    </React.Fragment>
                                );
                            })}
                    </tbody>
                </Table>
            </DataTablesContainer>
            <Header>{'Swegon Mollier Diagram'}</Header>
            {/* <LogoContainer>
                <SwegonLogo width={200} height={100} />
            </LogoContainer> */}
                <Graph
                    flowDataValues={flowDataValues}
                    points={points}
                    usePrintSize={isVisible}
                    canvasId={'print'}
                />
        </PrintOverlay>
    );
};
