import { SOG_GRAY_1, WHITE } from '@swegon-core/ui-components';
import styled from 'styled-components';

export const Container = styled.div`
    height: ${props =>
        props.isExternalApplication
            ? `calc(100vh - 60px - 1.625rem)` // 60px = header, 1.6rem = margin
            : `calc(100vh - 88px - 50px - 6px)`}; // 88px = header, 50px = button, 6px = margin
    margin: ${props => (props.isExternalApplication ? `0.75rem 1.5rem` : `0 0.125rem 0.125rem 0`)};
    background-color: ${WHITE};
    border: 1px solid ${SOG_GRAY_1};
    overflow: hidden;
`;

export const HideOnPrint = styled.div`
    display: contents;
    @media print {
        display: none !important;
    }
`;
