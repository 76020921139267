import { SOG_GRAY_0_5 } from '@swegon-core/ui-components';
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(31.25rem, 5fr) minmax(20rem, 1fr);
    grid-template-rows: 100px calc(100% - 100px);
    height: calc(100% - 2.6875rem);
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: 2rem;
    padding-top: 1rem;
`;

export const DataColumn = styled.div`
    display: grid;
    grid-template-rows: min-content min-content;
    grid-column: 2/3;
    grid-row: 1/3;
    border-left: 1px solid ${SOG_GRAY_0_5};
    overflow-x: hidden;
    overflow-y: auto;
`;
