import { geTemperatureLines, getKLineDatasets, getMoistureContentLines, getPointDatasets, getRelativeHumidityLineDatasets, getMixingsDatasets, getValue } from "./Graph.functions";
import { UNIT_ID, GRAPHS } from '../../lib/constants';
import { getAbbreviationByProperty } from '@swegon-core/swegon.conversions.javascript';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    getMoistureFromTemperatureAndHumidity,
    getEnthalpyFromMoistureAndHumidity
} from '../DiagramContainer.functions';

/**
 * GraphHelper is used for shared functions between serverside and client rendered graph
 */
class GraphHelper {
    constructor(points, typeOfGraph, scale, atmPressure, l, typeOfUnitSystem, locale, mixings, isExternalApplication) {
        this.points = points;
        this.typeOfGraph = typeOfGraph;
        this.scale = scale;
        this.atmPressure = atmPressure;
        this.l = l;
        this.typeOfUnitSystem = typeOfUnitSystem;
        this.locale = locale;
        this.mixings = mixings;
        this.isExternalApplication = isExternalApplication;

        this.showLabel = this.showLabel.bind(this);
    }

    showLabel(minValue, maxValue, currentValue, interval, currentXValue = null) {
        if (currentXValue !== null && minValue > currentXValue) {
            return false;
        }
        const range = maxValue - minValue;
        const showLabel = range <= 20 ? currentValue % 1 === 0 : currentValue % interval === 0;
        return showLabel;
    };

    getGraphConfig() {
        const { minMoisture, maxMoisture, minTemperature, maxTemperature } = this.scale;
        const callbackFunction = (val) => {
            return this.showLabel(minTemperature, maxTemperature, val, 5)
                ? getValue(val, UNIT_ID.globalTemperature, this.locale)
                : '';
        }
        const config = {
            type: 'line',
            plugins: [ChartDataLabels],
            data: {
                datasets: []
            },
            options: {
                layout: {
                    padding: {
                        top: this.typeOfGraph === GRAPHS.CIBS ? 50 : 10,
                        right: this.typeOfGraph === GRAPHS.CIBS ? 10 : 50,
                        bottom: this.typeOfGraph === GRAPHS.CIBS ? 10 : 50,
                        left: this.typeOfGraph === GRAPHS.CIBS ? 50 : 10
                    }
                },
                animation: false,
                plugins: {
                    legend: {
                        display: false
                    }
                },
                onHover: function (hoverItem) {
                    return hoverItem.datasetIndex === 3;
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    'temperature-axis': {
                        type: 'linear',
                        position: this.typeOfGraph === GRAPHS.CIBS ? 'bottom' : 'left',
                        min: minTemperature,
                        max: maxTemperature,
                        title: {
                            display: true,
                            text: `${this.l('quantities.temperatureDryBulb')}, T, (${getAbbreviationByProperty(
                                UNIT_ID.globalTemperature,
                                this.locale
                            )})`
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            drawTicks: false
                        },
                        ticks: {
                            stepSize: 1,
                            autoSkip: false,
                            callback: function (val) {
                                return callbackFunction(val);
                            },
                            padding: 20,
                            maxRotation: 0
                        }
                    },
                    'enthalpy-axis': {
                        type: 'linear',
                        position: this.typeOfGraph === GRAPHS.CIBS ? 'top' : 'bottom',
                        ticks: {
                            display: false
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        title: {
                            display: false
                        }
                    },
                    'moisture-axis': {
                        type: 'linear',
                        position: this.typeOfGraph === GRAPHS.CIBS ? 'right' : 'top',
                        min: minMoisture,
                        max: maxMoisture,
                        title: {
                            display: true,
                            text: `${this.l('quantities.moistureContent')}, x, (${getAbbreviationByProperty(
                                UNIT_ID.moistureContent,
                                this.locale
                            )})`
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            drawTicks: false
                        },
                        ticks: {
                            stepSize: 1,
                            autoSkip: false,
                            callback: function (val) {
                                return callbackFunction(val);
                            },
                            padding: 20,
                            maxRotation: 0
                        }
                    }
                }
            }
        };

        return config;
    };

    getAllDatasets(chart) {
        const allDatasets = [
            ...getMixingsDatasets(this.mixings, this.typeOfGraph, this.isExternalApplication),
            ...getPointDatasets(this.points, this.l, this.typeOfGraph, chart?.current, this.scale),
            ...getRelativeHumidityLineDatasets(this.atmPressure.value, this.scale, this.typeOfGraph),
            ...getKLineDatasets(
                this.atmPressure.value,
                this.l,
                this.scale,
                this.typeOfGraph,
                this.locale
            ),
            ...geTemperatureLines(this.atmPressure.value, this.scale, this.typeOfGraph),
            ...getMoistureContentLines(this.atmPressure.value, this.scale, this.typeOfGraph)
        ];
        return allDatasets;
    }
}

export default GraphHelper;