import {
    convert,
    convertAndFormatToCultureUnit,
    convertAndFormatToDefaultUnit,
    getCultureUnit,
    getDefaultUnit
} from '@swegon-core/swegon.conversions.javascript';
import { LANGUAGES, UNIT_SYSTEM } from '../../../lib/constants';

export const getConversionForUnitSystem =
    typeOfUnitSystem =>
    (value, propertyName, convertBack = false, format = true) => {
        if (isNaN(value) || !propertyName) return;

        if (value === '') return '';

        // We need to convert metric/imperial to locale in order for .conversions.javascript to work properly
        // SE and US are used in order to get metric and imperial respectively
        const locale = typeOfUnitSystem === UNIT_SYSTEM.METRIC ? LANGUAGES.SE : LANGUAGES.US;

        // Return unformatted value
        if (!format) {
            if (convertBack) {
                const defaultUnit = getDefaultUnit(propertyName);
                const cultureUnit = getCultureUnit(propertyName, locale);
                const convertedValue = convert(value, cultureUnit, defaultUnit, true);
                return convertedValue;
            } else {
                const defaultUnit = getDefaultUnit(propertyName);
                const cultureUnit = getCultureUnit(propertyName, locale);
                const convertedValue = convert(value, defaultUnit, cultureUnit);
                return convertedValue;
            }
        } else {
            if (convertBack) {
                const convertedAndFormatted = convertAndFormatToDefaultUnit(
                    value,
                    propertyName,
                    locale
                ).formattedValue;
                return parseFloat(convertedAndFormatted);
            } else {
                const convertedAndFormatted = convertAndFormatToCultureUnit(
                    value,
                    propertyName,
                    locale
                ).formattedValue;
                return parseFloat(convertedAndFormatted);
            }
        }
    };
