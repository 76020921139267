import { UNIT_ID } from '../../../lib/constants'

export const defaultVisualFlowDataValues = () => {
    return {
        globalTemperature: {
            id: UNIT_ID.globalTemperature,
            value: 20
        },
        atmPressure: {
            id: UNIT_ID.atmPressure,
            value: 1013
        },
        density: {
            id: UNIT_ID.density,
            value: 1.2
        }
    };
};

export const defaultFlowDataValues = () => {
    return {
        ...defaultVisualFlowDataValues(),
        globalTemperature: {
            ...defaultVisualFlowDataValues().globalTemperature,
            label: 'quantities.temperature'
        },
        atmPressure: {
            ...defaultVisualFlowDataValues().atmPressure,
            label: 'quantities.atmPressure'
        },
        density: {
            ...defaultVisualFlowDataValues().density,
            label: 'quantities.density'
        }
    };
};

export const defaultVisualPointValues = () => {
    return {
        pointTemperature: {
            id: UNIT_ID.pointTemperature,
            value: ''
        },
        relativeHumidity: {
            id: UNIT_ID.relativeHumidity,
            value: ''
        },
        moistureContent: {
            id: UNIT_ID.moistureContent,
            value: ''
        },
        enthalpy: {
            id: UNIT_ID.enthalpy,
            value: ''
        },
        pointAirflowToNext: {
            id: UNIT_ID.pointAirflowToNext,
            value: ''
        }
    };
};

export const defaultPointValues = () => {
    return {
        ...defaultVisualPointValues(),
        pointTemperature: {
            ...defaultVisualPointValues().pointTemperature,
            label: 'quantities.temperature',
            manuallyEntered: false
        },
        relativeHumidity: {
            ...defaultVisualPointValues().relativeHumidity,
            label: 'quantities.relativeHumidity',
            manuallyEntered: false
        },
        moistureContent: {
            ...defaultVisualPointValues().moistureContent,
            label: 'quantities.moistureContent',
            manuallyEntered: false
        },
        enthalpy: {
            ...defaultVisualPointValues().enthalpy,
            label: 'quantities.enthalpy',
            manuallyEntered: false
        },
        pointAirflowToNext: {
            ...defaultVisualPointValues().pointAirflowToNext,
            label: 'quantities.airflow',
            manuallyEntered: false
        }
    };
};