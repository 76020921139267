export const processTableHeader = {
    prefix: "points.prefix",
    name: "points.name",
    temperature: "quantities.temperature",
    atmPressure: "quantities.atmosphericPressure",
    relativeHumidity: "quantities.relativeHumidity",
    wetBulb: "quantities.wetBulb",
    moistureContent: "quantities.moistureContent",
    enthalpy: "quantities.enthalpy",
    airflow: "quantities.airflow",
    power: "quantities.power",
    sensiblePower: "quantities.sensiblePower",
    water: "quantities.water"
};