import { AIR_HUMIDITY, UNIT_ID } from '../constants';

export const getFormattedValue = value => {
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    return Intl.NumberFormat(locale).format(value);
};

export const isWetBulbUnit = (id, typeOfHumidity) => {
    return id === UNIT_ID.relativeHumidity && typeOfHumidity === AIR_HUMIDITY.WET_BULB;
};
