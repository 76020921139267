import styled from 'styled-components';
// import { SOG_GRAY_1 } from '@swegon-core/ui-components';

export const PrintOverlay = styled.div`
    display: ${props => (props.alwaysVisible ? 'contents' : 'none')};
    @media print {
        display: contents;
    }
`;

export const PrintOverlayLandscape = styled.div`
    @page {
        size: A4 landscape;
    }
`;

export const PrintOverlayPortrait = styled.div`
    @page {
        size: A4 portrait;
    }
`;

export const DataTablesContainer = styled.div`
    page-break-after: always;
    margin-top: 100px;
`;

export const Table = styled.table`
    margin: 1em;
    margin-bottom: 3em;
    border-collapse: collapse;
`;

export const TableHeader = styled.th`
    text-align: left;
`;

export const TableDataRow = styled.tr`
    border: solid black;
    border-width: 0 0 1px 0;
`;

export const TableData = styled.td`
    padding: 0 1em 0 0;
`;

export const Header = styled.h4`
    display: inline-flex;
    position: fixed;
    top: 0px;
`;

export const LogoContainer = styled.div`
    width: 200px;
    height: 100px;
    right: 30px;
    top: 5px;
    display: inline-flex;
    position: fixed;
`;

export const EmptyTableRow = styled.tr`
    height: 5px;
    border: rgb(0, 0, 0);
    border-width: 1px 0 0 0;
`;
