import React, { createContext, useState, useEffect, useMemo, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getObjectInLocalStorage, setObjectInLocalStorage } from './lib/localStorage';
import { migrateTabs } from './lib/tabsMigration';
import { createNewTab, createTabsFromTabsContent } from './lib/tabs'
import { defaultVisualFlowDataValues, defaultFlowDataValues, defaultVisualPointValues, defaultPointValues } from './lib/defaultValues'
import { Context as UnitConversionContext } from './UnitConversionContext';
import { AIR_HUMIDITY, SEASONS, UNIT_ID } from '../../lib/constants';
import { getWetBulb } from '../../components/DiagramContainer.functions';
import { Context as SettingsContext } from '../Settings/SettingsContext';

export const Context = createContext({});

const getTabs = (flowDataValues, tabsContent, season) => {
    if (!tabsContent || !tabsContent.length) {
        return getObjectInLocalStorage('tabs') || [createNewTab('Diagram 1', flowDataValues)];
    }

    return createTabsFromTabsContent(tabsContent, flowDataValues, season, false);
};

const getFlowDataValuesBySeason = (season, flowDataSeasonValues) => {
    return season === SEASONS.SUMMER ? flowDataSeasonValues.summer : flowDataSeasonValues.winter;
}

export const ApplicationContext = ({
    internalApplicationData,
    children // Child Components
}) => {
    const { tabsContent, flowDataValuesFromAhu } = internalApplicationData ?? {};
    const { getConversion } = useContext(UnitConversionContext);
    const { typeOfUnitSystem, typeOfHumidity, isExternalApplication } = useContext(SettingsContext);
    const [season, setSeason] = useState(SEASONS.SUMMER);
    const [flowDataValues, setFlowDataValues] = useState(
        flowDataValuesFromAhu ? getFlowDataValuesBySeason(season, flowDataValuesFromAhu) : defaultFlowDataValues()
    );
    if(typeof flowDataValues.globalTemperature.value === 'string'){
        flowDataValues.globalTemperature.value = parseFloat(flowDataValues.globalTemperature.value);
    }
    const flowDataValuesRef = useRef(flowDataValues);
    const [visualFlowDataValues, setVisualFlowDataValues] = useState(
        flowDataValuesFromAhu ? getFlowDataValuesBySeason(season, flowDataValuesFromAhu) : defaultVisualFlowDataValues()
    );
    const updatedRef = useRef(() => getObjectInLocalStorage('updated') || 0);
    const [tabs, setTabs] = useState(() =>
        migrateTabs(getTabs(flowDataValues, tabsContent, season))
    );
    const [active, setActive] = useState(() => {
        const activeInSessionStorage = sessionStorage.getItem('active');
        return !tabs || tabs.length === 0
            ? null
            : tabs.some(tab => tab.id === activeInSessionStorage)
            ? activeInSessionStorage
            : tabs[0].id;
    });
    const activeTabIndex = useMemo(() => {
        if (tabs) {
            const index = tabs.findIndex(tab => tab.id === active);
            return index !== -1 ? index : 0;
        }

        return 0;
    }, [active, tabs]);

    const [shouldStore, setShouldStore] = useState(false);

    const [pointsAndLines, setPointsAndLines] = useState({ points: [], lines: [] });
    const pointsRef = useRef(pointsAndLines);

    const [lineToNext, setLineToNext] = useState(true);
    const [isAddPointVisible, setVisible] = useState(false);
    const [name, setName] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [pointValues, setPointValues] = useState(defaultPointValues());
    const [visualPointValues, setVisualPointValues] = useState(defaultVisualPointValues());
    const [airflowToNext, setAirflowToNext] = useState(null);

    const addTab = () => {
        const updatedTabs = [...tabs];
        const index = updatedTabs.length + 1;
        const newTab = createNewTab(`Diagram ${index}`, flowDataValues);
        updatedTabs.splice(updatedTabs.length, 0, newTab);
        updatedRef.current = Date.now();
        setTabs(updatedTabs);
        setActive(newTab.id);
        setShouldStore(true);
    };

    const importTabs = newTabs => {
        newTabs.forEach(newTab => {
            newTab.id = uuidv4();
        });
        const migratedNewTabs = migrateTabs(newTabs);
        setTabs(tabs.concat(migratedNewTabs));
    } 

    const closeTab = id => {
        const updatedTabs = [...tabs];
        const tabIndex = updatedTabs.findIndex(tab => tab.id === id);
        updatedTabs.splice(tabIndex, 1);

        if (updatedTabs.length === 0) {
            const index = updatedTabs.length + 1;
            const tab = createNewTab(`Diagram ${index}`, flowDataValues);
            updatedTabs.splice(0, 0, tab);
        }

        updatedRef.current = Date.now();
        setTabs(updatedTabs);
        setShouldStore(true);

        if (!updatedTabs.some(tab => tab.id === active)) {
            const newIndex = Math.max(0, tabIndex - 1);
            setActive(updatedTabs[newIndex].id);
        }
    };

    const setTitle = (index, newTitle) => {
        const updatedTab = { ...tabs[index], title: newTitle };
        const newTabs = tabs;
        newTabs.splice(index, 1, updatedTab);

        updatedRef.current = Date.now();
        setTabs([...newTabs]);
        setShouldStore(true);
    };

    const updateActiveTab = () => {
        updatedRef.current = Date.now();
        tabs[activeTabIndex].updated = updatedRef.current;
    };

    const moveTab = (dragIndex, hoverIndex) => {
        const newTabs = [...tabs];
        newTabs.splice(dragIndex, 1);
        newTabs.splice(hoverIndex, 0, tabs[dragIndex]);
        setTabs(newTabs);
        setShouldStore(true);
    };

    const state = {
        active,
        setActive,
        tabs,
        activeTabIndex,
        addTab,
        importTabs,
        closeTab,
        setTitle,
        pointsAndLines,
        setPointsAndLines,
        pointsRef,
        lineToNext,
        setLineToNext,
        airflowToNext,
        setAirflowToNext,
        isAddPointVisible,
        setVisible,
        name,
        setName,
        isEditing,
        setIsEditing,
        flowDataValues,
        setFlowDataValues,
        visualFlowDataValues,
        setVisualFlowDataValues,
        flowDataValuesRef,
        pointValues,
        setPointValues,
        visualPointValues,
        setVisualPointValues,
        setShouldStore,
        visualFlowDataValues,
        setVisualFlowDataValues,
        moveTab,
        season,
        setSeason
    };

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        setPointsAndLines({
            points: tabs[activeTabIndex].content.points,
            lines: tabs[activeTabIndex].content.lines
        });
        setLineToNext(tabs[activeTabIndex].content.lineToNext);
        setVisible(tabs[activeTabIndex].content.isAddPointVisible);
        setName(tabs[activeTabIndex].content.name);
        setIsEditing(tabs[activeTabIndex].content.isEditing);
        setFlowDataValues(tabs[activeTabIndex].content.flowDataValues);
        setPointValues(tabs[activeTabIndex].content.pointValues);
        setSeason(tabs[activeTabIndex].content.season);
    }, [activeTabIndex, tabs]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        const { points, lines } = pointsAndLines;

        tabs[activeTabIndex].content.points = points;
        tabs[activeTabIndex].content.lines = lines;
        updateActiveTab();
    }, [pointsAndLines]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.lineToNext != lineToNext) {
            tabs[activeTabIndex].content.lineToNext = lineToNext;
            updateActiveTab();
        }
    }, [lineToNext]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.isAddPointVisible != isAddPointVisible) {
            tabs[activeTabIndex].content.isAddPointVisible = isAddPointVisible;
            updateActiveTab();
        }
    }, [isAddPointVisible]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.name != name) {
            tabs[activeTabIndex].content.name = name;
            updateActiveTab();
        }
    }, [name]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.isEditing != isEditing) {
            tabs[activeTabIndex].content.isEditing = isEditing;
            updateActiveTab();
        }
    }, [isEditing]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.flowDataValues != flowDataValues) {
            tabs[activeTabIndex].content.flowDataValues = flowDataValues;
            updateActiveTab();
        }
    }, [flowDataValues]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) {
            return;
        }

        if (tabs[activeTabIndex].content.pointValues != pointValues) {
            tabs[activeTabIndex].content.pointValues = pointValues;
            updateActiveTab();
        }
    }, [pointValues]);

    useEffect(() => {
        if (!tabs || activeTabIndex < 0 || activeTabIndex >= tabs.length) return;
        if (isExternalApplication || tabs[activeTabIndex].content.season === season) return;

        tabs[activeTabIndex].content.season = season;
        updateActiveTab();

        const points = tabsContent[activeTabIndex][season].pointsAndLines.points;
        const lines = tabsContent[activeTabIndex][season].pointsAndLines.lines;
        setPointsAndLines({ points: points, lines: lines });
    }, [season]);

    useEffect(() => {
        sessionStorage.setItem('active', active);
    }, [active]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        window.addEventListener('storage', onStorageUpdate);
        return () => {
            window.removeEventListener('storage', onStorageUpdate);
        };
    }, []);

    useEffect(() => {
        if (shouldStore) {
            setShouldStore(false);
            if (updatedRef.current > getObjectInLocalStorage('updated')) {
                setObjectInLocalStorage('updated', updatedRef.current);
                setObjectInLocalStorage('tabs', tabs);
            }
        }
    }, [shouldStore]);

    useEffect(() => {
        const newVisualFlowDataValues = JSON.parse(JSON.stringify(visualFlowDataValues));
        for (const [key, values] of Object.entries(newVisualFlowDataValues)) {
            newVisualFlowDataValues[key].value = getConversion(
                tabs[activeTabIndex].content.flowDataValues[key].value,
                visualFlowDataValues[key].id
            );
        }

        setVisualFlowDataValues({
            ...newVisualFlowDataValues
        });

        const newVisualPointValues = JSON.parse(JSON.stringify(visualPointValues));
        for (const [key, values] of Object.entries(newVisualPointValues)) {
            if (key === UNIT_ID.relativeHumidity && typeOfHumidity === AIR_HUMIDITY.WET_BULB) {
                const dryTemperature =
                    tabs[activeTabIndex].content.pointValues.pointTemperature.value;
                const pressure = flowDataValues.atmPressure.value;
                const wetBulb = getWetBulb(
                    dryTemperature,
                    tabs[activeTabIndex].content.pointValues[key].value,
                    pressure
                );
                newVisualPointValues[key].value = getConversion(wetBulb, UNIT_ID.pointTemperature);
            } else {
                newVisualPointValues[key].value = getConversion(
                    tabs[activeTabIndex].content.pointValues[key].value,
                    visualPointValues[key].id
                );
            }
        }

        setVisualPointValues({
            ...newVisualPointValues
        });
    }, [typeOfUnitSystem, activeTabIndex, tabs]);

    useEffect(() => {
        const temperature = tabs[activeTabIndex].content.pointValues.pointTemperature.value;
        const relativeHumidity = tabs[activeTabIndex].content.pointValues.relativeHumidity.value;
        const pressure = flowDataValues.atmPressure.value;
        const wetBulb = getWetBulb(temperature, relativeHumidity, pressure);
        visualPointValues.relativeHumidity.value =
            typeOfHumidity === AIR_HUMIDITY.WET_BULB
                ? getConversion(wetBulb, UNIT_ID.pointTemperature)
                : relativeHumidity;
    }, [typeOfHumidity]);

    const onStorageUpdate = e => {
        const { key, newValue } = e;
        if (key === 'tabs') {
            const newTabs = (newValue && migrateTabs(JSON.parse(newValue))) || [
                createNewTab('Diagram 1', flowDataValues)
            ];
            if (!newTabs.some(tab => tab.id === active)) {
                setActive(newTabs[0].id);
            }
            setTabs(newTabs);
        }
    };

    return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default ApplicationContext;
