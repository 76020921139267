import SwegonBrownRegular from '../src/assets/fonts/SwegonBrownWeb-Regular.woff2';
import SwegonBrownLight from '../src/assets/fonts/SwegonBrownWeb-Light.woff2';
import SwegonBrownBold from '../src/assets/fonts/SwegonBrownWeb-Bold.woff2';
import { createGlobalStyle } from 'styled-components';
import { DSGT_GRAY_GREEN_0_5, SOG_GRAY_3, SOG_GRAY_4, WHITE } from '@swegon-core/ui-components';

export const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'SwegonBrown';
        src: local('SwegonBrownRegular'), url(${SwegonBrownRegular});
        font-weight: normal;
    }

    @font-face {
        font-family: 'SwegonBrown';
        src: local('SwegonBrownLight'), url(${SwegonBrownLight});
        font-weight: 100;
    }

    @font-face {
        font-family: 'SwegonBrown';
        src: local('SwegonBrownBold'), url(${SwegonBrownBold});
        font-weight: bold;
    }

    #root{
        @media print {
            overflow: visible!important;
        }
    }

    h1 {
        font: 700 3rem/3.5rem "SwegonBrown";
    }

    h2 {
        font: 700 2.5rem/3rem "SwegonBrown";
    }

    h3 {
        font: 700 2.25rem/2.75rem "SwegonBrown";
    }

    h4 {
        font: 700 1.5rem/2rem "SwegonBrown";
    }

    h5 {
        font: 700 1.25rem/1.75rem "SwegonBrown";
    }

    h6 {
        font: 400 1.25rem/1.75rem "SwegonBrown";
    }

    body {
        margin: 0;
        padding: 0;
        font: 400 1rem/1.5rem "SwegonBrown";
        background-color: ${DSGT_GRAY_GREEN_0_5};
        @media print {
            background-color: ${WHITE};
        }
    }

    p {
        font: 400 1rem/1.5rem "SwegonBrown";
        color: ${SOG_GRAY_4};
    }

    label {
        font: 400 0.875rem/1.25rem "SwegonBrown";
        color: ${SOG_GRAY_3};
    }
`;

export default GlobalStyle;
