import React, { useContext, useRef } from 'react';
import { AHUD } from '@swegon-core/ui-components';

import { Context as ApplicationContext } from '../modules/Application/ApplicationContext';
import { Context as TranslationContext } from '../modules/Translation/TranslationContext';

export const Importer = ({ }) => {
    const { IconButton, Import } = AHUD;
    const inputFile = useRef(null);
    const { l } = useContext(TranslationContext);
    const { importTabs } = useContext(ApplicationContext);

    const importClick = () => {
        inputFile.current.click();
    };

    const handleFileUpload = e => {
        const fileReader = new FileReader();
        
        fileReader.onload = e => {
            const newTabs = JSON.parse(e.target.result);
            importTabs(newTabs);
            inputFile.current.value = '';
        };
        if (e.target.files.length) {
            fileReader.readAsText(e.target.files[0], "UTF-8");
        }
    };

    return (
        <>
            <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleFileUpload}/>
            <IconButton
                onClick={() => importClick()}
                tooltip={l('header.import')}
                testid={'importButton'}
            >
                <Import />
            </IconButton>
        </>
    );
};