import React, { useEffect, useRef, useContext, useMemo } from 'react';
import Chart from 'chart.js/auto';
import { Container, Canvas } from './Graph.styles';
import {
    getScale,
    getValue
} from './Graph.functions';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Context as SettingsContext } from '../../modules/Settings/SettingsContext';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext';
import { PRINT_ORIENTATIONS, UNIT_ID, UNIT_SYSTEM, LANGUAGES, GRAPHS } from '../../lib/constants';
import GraphHelper from './GraphHelper';

export const Graph = ({ flowDataValues, points, mixings, usePrintSize, canvasId = 'graph' }) => {
    const { l } = useContext(TranslationContext);
    const { printOrientation, userAxisValues, typeOfGraph, typeOfUnitSystem, isExternalApplication } =
        useContext(SettingsContext);
    const { getAbbreviation } = useContext(UnitConversionContext);
    const { atmPressure } = flowDataValues;
    const graphReference = useRef(null);
    const chart = useRef(null);
    const scale = useMemo(() => {
        return getScale(points, userAxisValues);
    }, [points, userAxisValues]);
    const locale = typeOfUnitSystem === UNIT_SYSTEM.METRIC ? LANGUAGES.SE : LANGUAGES.US;
    const graphHelper = new GraphHelper(points, typeOfGraph, scale, atmPressure, l, typeOfUnitSystem, locale, mixings, isExternalApplication);

    const tooltip = () => {
        return {
            yAlign: function (context) {
                if (context.tooltipItems.length > 0 && context.tooltipItems[0].parsed.y < -10) {
                    return 'bottom';
                }
                return 'top';
            },
            displayColors: false,
            caretPadding: 8,
            callbacks: {
                title: function (context) {
                    return '';
                },
                label: function (context) {
                    return getRelativeHumidityLineHoverLabel(context);
                }
            },
            bodyFont: {
                size: 12
            }
        }
    };

    const getRelativeHumidityLineHoverLabel = (context) => {
        const currentData = context.dataset.data[context.dataIndex];
        const temperature = typeOfGraph === GRAPHS.CIBS ? currentData.x : currentData.y;
        const moistureContent = typeOfGraph === GRAPHS.CIBS
            ? currentData.y.toPrecision(3)
            : currentData.x.toPrecision(3);
        return [
            `${currentData.name}`,
            `${context.dataset.labels[0]}: ${getValue(
                temperature,
                UNIT_ID.globalTemperature,
                locale
            )} ${getAbbreviation(UNIT_ID.globalTemperature)}`,
            `${context.dataset.labels[1]}: ${getValue(
                currentData.relativeHumidity,
                UNIT_ID.relativeHumidity,
                locale
            )} ${getAbbreviation(UNIT_ID.relativeHumidity)}`,
            `${context.dataset.labels[2]}: ${getValue(
                moistureContent,
                UNIT_ID.moistureContent,
                locale
            )} ${getAbbreviation(UNIT_ID.moistureContent)}`,
            `${context.dataset.labels[3]}: ${getValue(
                currentData.enthalpy,
                UNIT_ID.enthalpy,
                locale
            )} ${getAbbreviation(UNIT_ID.enthalpy)}`
        ];
    };

    useEffect(() => {
        // TODO: Move all conversion out so that everything is converted before
        const config = graphHelper.getGraphConfig();
        config.options.plugins.tooltip = tooltip();
        const graphReferenceCurrent = graphReference.current;
        const ctx = graphReferenceCurrent.getContext('2d');
        if (chart.current) {
            chart.current.destroy();
        }

        chart.current = new Chart(ctx, config);
        Chart.defaults.font.size = 14;
        Chart.defaults.font.family = 'SwegonBrown';
    }, [graphReference, l, points, typeOfGraph, userAxisValues, typeOfUnitSystem]);

    useEffect(() => {
        if (usePrintSize && canvasId === 'print') {
            const width = printOrientation === PRINT_ORIENTATIONS.PORTRAIT ? 950 : 1000;
            const height = printOrientation === PRINT_ORIENTATIONS.PORTRAIT ? 900 : 670;
            chart.current.resize(width, height);
            chart.current.update();
        }
    }, [usePrintSize, chart.current, printOrientation, canvasId]);

    useEffect(() => {
        if (!chart.current) {
            return;
        }

        // TODO: Move all conversion out so that everything is converted before
        chart.current.data.datasets = graphHelper.getAllDatasets(chart);
        chart.current.update();
    }, [chart, l, points, scale, atmPressure, typeOfGraph, typeOfUnitSystem]);

    return (
        <Container>
            <Canvas ref={graphReference} id={canvasId} />
        </Container>
    );
};
