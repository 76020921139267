import React, { useContext } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { pointLine } from '../../assets/icons/pointLine';
import { getFormattedValue } from '../../lib/utility/helpFunctions';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext';
import { DataString } from './DataString';
import { Container, RectangleContainer, Data } from './Line.styles';

export const Line = ({ line, index, color, isDashed = false }) => {
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);

    const { values } = line;

    return (
        <Draggable draggableId={`draggableLine-${index}`} index={index} isDragDisabled={true}>
            {provided => (
                <Container
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    data-testid={`line-${index}`}
                >
                    <RectangleContainer>
                        {pointLine(color, isDashed ? '4 13' : '1 1')}
                    </RectangleContainer>
                    <Data>
                        {Object.entries(values).map(([key, values]) => (
                            <DataString
                                key={key}
                                label={values.label}
                                value={getFormattedValue(getConversion(values.value, values.id))}
                                unit={getAbbreviation(values.id)}
                                manuallyEntered={values.manuallyEntered}
                            />
                        ))}
                    </Data>
                </Container>
            )}
        </Draggable>
    );
};
