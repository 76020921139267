export function findTranslationForKey(key = 'No.Key', translations = {}) {
    if (!translations || typeof translations != 'object' || Array.isArray(translations)) {
        return key;
    }

    const keys = key.split('.');
    let val = translations;

    for (let i = 0; i < keys.length; i++) {
        if (val.hasOwnProperty(keys[i])) {
            val = val[keys[i]];
        } else {
            // Nothing found, try again further down in the object
            val = translations;
        }
    }

    return typeof val === 'object' ? key : val;
}

export default findTranslationForKey;
