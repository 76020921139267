import React from 'react';

export const pointLine = (color, dashArray) => {
    return (
        <svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'>
            <rect
                width='100%'
                height='100%'
                fill='none'
                stroke={color || 'black'}
                strokeWidth='6'
                strokeDasharray={dashArray || '1 1'}
                strokeDashoffset='0'
                strokeLinecap='square'
            />
        </svg>
    );
};
