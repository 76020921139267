import React, { createContext, useContext } from 'react';
import { getConversionForUnitSystem } from './lib/getConversionForUnitSystem';
import { getAbbreviationForUnitSystem } from './lib/getAbbreviationForUnitSystem';
import { Context as SettingsContext } from '../Settings/SettingsContext';

export const Context = createContext({});

export const UnitConversionContext = ({
    children // Child components
}) => {
    const { typeOfUnitSystem } = typeof window === 'undefined' ? UNIT_SYSTEM.METRIC : useContext(SettingsContext);

    const state = {
        getConversion: getConversionForUnitSystem(typeOfUnitSystem),
        getAbbreviation: getAbbreviationForUnitSystem(typeOfUnitSystem)
    };

    return <Context.Provider value={state}>{children}</Context.Provider>;
};
