import React, { useContext } from 'react';
import { AHUD } from '@swegon-core/ui-components';
import { saveAs } from 'file-saver';

import { Context as ApplicationContext } from '../modules/Application/ApplicationContext';
import { Context as TranslationContext } from '../modules/Translation/TranslationContext';

export const Exporter = ({ }) => {
    const { IconButton, Export } = AHUD;
    const { l } = useContext(TranslationContext);
    const { tabs } = useContext(ApplicationContext);

    const exportClick = () => {
        const fileName = `mollierExport_${new Date().toISOString().replace(/[^\w]/g, "")}.mol`;
        var blob = new Blob([JSON.stringify(tabs)], {type: "application/json;charset=utf-8"});
        saveAs(blob, fileName);
    };

    return (
        <>
            <IconButton
                onClick={() => exportClick()}
                tooltip={l('header.export')}
                testid={'exportButton'}
            >
                <Export />
            </IconButton>
        </>
    );
};