import React, { useContext, useMemo } from 'react';
import { DiagramContainer } from './DiagramContainer';
import { AHUD } from '@swegon-core/ui-components';
import { Context as ApplicationContext } from '../modules/Application/ApplicationContext';
import { Context as SettingsContext } from '../modules/Settings/SettingsContext';

export const TabGroupWrapper = () => {
    const { TabGroup } = AHUD;
    const { active, setActive, tabs, activeTabIndex, addTab, closeTab, setTitle, moveTab } =
        useContext(ApplicationContext);
    const { isExternalApplication } = useContext(SettingsContext);

    const content = <DiagramContainer key={0} />;

    const tabsToShow = useMemo(() => {
        const diagramTabs =
            tabs?.map((tab, i) => {
                return {
                    ...tab,
                    onClick: () => setActive(tab.id),
                    onDrag: (sourceIndex, targetIndex) => moveTab(sourceIndex, targetIndex),
                    close: isExternalApplication ? () => closeTab(tab.id) : null, // Tabs within ahu should be based on number of operating setups
                    content: active === tab.id ? content : <></>,
                    tabIndex: i
                };
            }) || [];

        if (!isExternalApplication) {
            return diagramTabs;
        }

        return [
            ...diagramTabs,
            {
                id: '',
                title: '',
                onClick: () => addTab(),
                close: null,
                content: <></>,
                tabIndex: tabs?.length || 0,
                lastPosition: true,
                tabType: 'add'
            }
        ];
    }, [tabs, setActive, closeTab, addTab, active]);

    return (
        <TabGroup
            items={tabsToShow}
            activeIndex={activeTabIndex}
            setTitle={setTitle}
            withoutBorder={true}
        />
    );
};
