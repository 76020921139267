import { SOG_GRAY_3 } from '@swegon-core/ui-components';
import styled from 'styled-components';

export const DataTable = styled.div`
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 0.5em 1em;
    align-items: center;
    padding: 0.625rem 1.25rem;
`;

export const Label = styled.p`
    font-size: 0.875rem;
    grid-column: 1/2;
    margin: 0;
    font-weight: bold;
`;

export const DensityContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const Density = styled.p`
    font-size: 0.875rem;
    padding-left: 0.5rem;
    flex-grow: 1;
`;

export const Unit = styled.label`
    margin: 0;
    padding-right: 0.375rem;
    color: ${SOG_GRAY_3};
`;
