import React, { createContext, useState, useEffect, useCallback, useContext } from 'react';
import { getTranslations, getLanguages } from '../../api/languageApi';
import { Context as SettingsContext } from '../Settings/SettingsContext';

import { fetchTranslations, fetchLanguages, findTranslationForKey } from './lib';

export const Context = createContext({});

export const TranslationContext = ({
    lFromAhu,
    children // Child Components
}) => {
    const { locale, isExternalApplication } = useContext(SettingsContext);
    // const locale = 'sv-se';
    // const isExternalApplication = false;
    const [translations, setTranslations] = useState({});
    const [languages, setLanguages] = useState([]);

    const l = useCallback(
        key => {
            if (lFromAhu) {
                return lFromAhu(key);
            } else {
                return findTranslationForKey(key, translations);
            }
        },
        [translations]
    );

    const state = {
        translations,
        languages,
        l
    };

    useEffect(() => {
        if (!locale || !isExternalApplication) {
            return;
        }

        fetchTranslations(getTranslations, locale, setTranslations);
    }, [getTranslations, locale]);

    useEffect(() => {
        fetchLanguages(getLanguages, setLanguages);
    }, [getLanguages]);

    return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default TranslationContext;
