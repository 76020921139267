import React, { useContext } from 'react';
import { Container, Label, Value, Unit } from './DataString.styles';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';

export const DataString = ({ name, index, label, value, unit, manuallyEntered }) => {
    const { l } = useContext(TranslationContext);

    return (
        <Container>
            <Label manuallyEntered={manuallyEntered} data-testid={`${name}Label-${index}`}>
                {l(label)}
            </Label>
            <Value manuallyEntered={manuallyEntered} data-testid={`${name}Value-${index}`}>
                {value}
            </Value>
            <Unit manuallyEntered={manuallyEntered} data-testid={`${name}Unit-${index}`}>
                {unit}
            </Unit>
        </Container>
    );
};
