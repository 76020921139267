import 'regenerator-runtime/runtime.js';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from '../src/App.jsx';

const { MOLLIER_ENV, npm_package_version } = process.env;

if (MOLLIER_ENV && MOLLIER_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://065830383cb64b18856d2512a266bd69@o66496.ingest.sentry.io/6071004',
        integrations: [new Integrations.BrowserTracing()],
        environment: MOLLIER_ENV,
        tracesSampleRate: MOLLIER_ENV === 'production' ? 0.2 : 0.0
    });

    Sentry.setTag('version', npm_package_version);
}

ReactDOM.render(<App />, document.getElementById('root'));
