import React, { useContext } from 'react';
import { DataTable, Label, Density, Unit, DensityContainer } from './FlowData.styles';
import { AHUD, InputField } from '@swegon-core/ui-components';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Context as ApplicationContext } from '../../modules/Application/ApplicationContext';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext';
import { getFormattedValue } from '../../lib/utility/helpFunctions';

export const FlowData = ({ values, onBlur, onEnter, typeOfUnitSystem }) => {
    const { l } = useContext(TranslationContext);
    const { visualFlowDataValues, setVisualFlowDataValues } = useContext(ApplicationContext);
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);
    const { ExpansionPanel } = AHUD;

    const { globalTemperature, atmPressure, density } = values;

    return (
        <ExpansionPanel label={l('flowData.title')}>
            <DataTable>
                <Label>{l(globalTemperature.label)}</Label>
                <InputField
                    type='number'
                    id={globalTemperature.id}
                    value={
                        !Number.isNaN(visualFlowDataValues.globalTemperature.value)
                            ? visualFlowDataValues.globalTemperature.value
                            : ''
                    }
                    unit={getAbbreviation(globalTemperature.id, typeOfUnitSystem)}
                    onChange={e =>
                        setVisualFlowDataValues({
                            ...visualFlowDataValues,
                            globalTemperature: {
                                ...visualFlowDataValues.globalTemperature,
                                value: e.valueAsNumber
                            }
                        })
                    }
                    onBlur={e => onBlur(e)}
                    onEnter={e => onEnter(e)}
                    enableHotjar={true}
                />

                <Label>{l(atmPressure.label)}</Label>
                <InputField
                    type='number'
                    id={atmPressure.id}
                    value={
                        !Number.isNaN(visualFlowDataValues.atmPressure.value)
                            ? visualFlowDataValues.atmPressure.value
                            : ''
                    }
                    unit={getAbbreviation(atmPressure.id, typeOfUnitSystem)}
                    onChange={e =>
                        setVisualFlowDataValues({
                            ...visualFlowDataValues,
                            atmPressure: {
                                ...visualFlowDataValues.atmPressure,
                                value: e.valueAsNumber
                            }
                        })
                    }
                    onBlur={e => onBlur(e)}
                    onEnter={e => onEnter(e)}
                    enableHotjar={true}
                />
                <Label>{l(density.label)}</Label>
                <DensityContainer>
                    <Density>
                        {getFormattedValue(visualFlowDataValues.density.value)}
                    </Density>
                    <Unit>{getAbbreviation(density.id, typeOfUnitSystem)}</Unit>
                </DensityContainer>
            </DataTable>
        </ExpansionPanel>
    );
};
