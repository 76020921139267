import React, { useRef, useContext } from 'react';
import { Container, Name, Footer, Label } from './AddPoint.styles.js';
import { AHUD, Button, InputField } from '@swegon-core/ui-components';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext.jsx';
import { Context as ApplicationContext } from '../../modules/Application/ApplicationContext.jsx';
import { Context as SettingsContext } from '../../modules/Settings/SettingsContext.jsx';
import { AIR_HUMIDITY } from '../../lib/constants.js';

export const AddPoint = ({
    name,
    values,
    lineToNext,
    onChange,
    onClick,
    close,
    isVisible,
    isEditing
}) => {
    const { l } = useContext(TranslationContext);
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);
    const { visualPointValues, setVisualPointValues } = useContext(ApplicationContext);
    const { typeOfHumidity } = useContext(SettingsContext);
    const { Overlay, Checkbox } = AHUD;

    const { pointTemperature, relativeHumidity, moistureContent, enthalpy, pointAirflowToNext } =
        values;
    const canAddPoint = useRef(false); // Need to use ref instead of state as InputField needs current render-value for disable to work properly

    const isDisabled = value => {
        const enteredValues = Object.entries(values).filter(
            ([key, values]) => key !== 'pointAirflowToNext' && values.manuallyEntered
        );
        if (enteredValues.length === 2) {
            canAddPoint.current = true;
        } else {
            canAddPoint.current = false;
        }

        if (canAddPoint.current && !value.manuallyEntered) {
            return true;
        }
        return false;
    };

    return (
        <Overlay width={'400px'} isVisible={isVisible} close={close} label=''>
            <form onSubmit={() => {}}>
                <Container>
                    <Name>
                        <InputField
                            label={l('points.name')}
                            id='name'
                            testid='nameInput'
                            type='text'
                            value={name}
                            placeholder={l('points.placeHolderName')}
                            onChange={onChange}
                            autoFocus
                            enableHotjar={true}
                        />
                    </Name>
                    <InputField
                        label={l(pointTemperature.label)}
                        id={pointTemperature.id}
                        testid='pointTemperatureInput'
                        type='number'
                        value={
                            !Number.isNaN(visualPointValues.pointTemperature.value)
                                ? visualPointValues.pointTemperature.value
                                : ''
                        }
                        placeholder='0'
                        onChange={e => {
                            onChange(e),
                                setVisualPointValues({
                                    ...visualPointValues,
                                    pointTemperature: {
                                        ...visualPointValues.pointTemperature,
                                        value: e.valueAsNumber
                                    }
                                });
                        }}
                        unit={getAbbreviation(pointTemperature.id)}
                        fontWeight={pointTemperature.manuallyEntered ? 'bold' : 'normal'}
                        disabled={isDisabled(pointTemperature)}
                        enableHotjar={true}
                    />
                    <InputField
                        label={
                            typeOfHumidity === AIR_HUMIDITY.RELATIVE_HUMIDITY
                                ? l(relativeHumidity.label)
                                : l('quantities.wetBulb')
                        }
                        id={relativeHumidity.id}
                        testid='relativeHumidityInput'
                        type='number'
                        value={
                            !Number.isNaN(visualPointValues.relativeHumidity.value)
                                ? visualPointValues.relativeHumidity.value
                                : ''
                        }
                        placeholder='0'
                        onChange={e => {
                            onChange(e),
                                setVisualPointValues({
                                    ...visualPointValues,
                                    relativeHumidity: {
                                        ...visualPointValues.relativeHumidity,
                                        value: e.valueAsNumber
                                    }
                                });
                        }}
                        unit={
                            typeOfHumidity === AIR_HUMIDITY.RELATIVE_HUMIDITY
                                ? getAbbreviation(relativeHumidity.id)
                                : getAbbreviation(pointTemperature.id)
                        }
                        fontWeight={relativeHumidity.manuallyEntered ? 'bold' : 'normal'}
                        disabled={isDisabled(relativeHumidity)}
                        enableHotjar={true}
                    />
                    <InputField
                        label={l(moistureContent.label)}
                        id={moistureContent.id}
                        testid='moistureContentInput'
                        type='number'
                        value={
                            !Number.isNaN(visualPointValues.moistureContent.value)
                                ? visualPointValues.moistureContent.value
                                : ''
                        }
                        placeholder='0'
                        onChange={e => {
                            onChange(e),
                                setVisualPointValues({
                                    ...visualPointValues,
                                    moistureContent: {
                                        ...visualPointValues.moistureContent,
                                        value: e.valueAsNumber
                                    }
                                });
                        }}
                        unit={getAbbreviation(moistureContent.id)}
                        fontWeight={moistureContent.manuallyEntered ? 'bold' : 'normal'}
                        disabled={isDisabled(moistureContent)}
                        enableHotjar={true}
                    />
                    <InputField
                        label={l(enthalpy.label)}
                        id={enthalpy.id}
                        testid='enthalpyInput'
                        key={enthalpy.id}
                        type='number'
                        value={
                            !Number.isNaN(visualPointValues.enthalpy.value)
                                ? visualPointValues.enthalpy.value
                                : ''
                        }
                        placeholder='0'
                        onChange={e => {
                            onChange(e),
                                setVisualPointValues({
                                    ...visualPointValues,
                                    enthalpy: {
                                        ...visualPointValues.enthalpy,
                                        value: e.valueAsNumber
                                    }
                                });
                        }}
                        unit={getAbbreviation(enthalpy.id)}
                        fontWeight={enthalpy.manuallyEntered ? 'bold' : 'normal'}
                        disabled={isDisabled(enthalpy)}
                        enableHotjar={true}
                    />
                    <Checkbox
                        id='lineToNext'
                        testid='lineToNextInput'
                        label={l('points.connectToNextPoint')}
                        checked={lineToNext}
                        onChange={onChange}
                    />
                    <InputField
                        label={l('points.airflowToNextPoint')}
                        id={pointAirflowToNext.id}
                        testid='airflowToNextInput'
                        key={pointAirflowToNext.id}
                        type='number'
                        value={
                            !Number.isNaN(visualPointValues.pointAirflowToNext?.value)
                                ? visualPointValues.pointAirflowToNext?.value
                                : ''
                        }
                        placeholder={getConversion(1, pointAirflowToNext.id)}
                        onChange={e => {
                            onChange(e),
                                setVisualPointValues({
                                    ...visualPointValues,
                                    pointAirflowToNext: {
                                        ...visualPointValues.pointAirflowToNext,
                                        value: e.valueAsNumber
                                    }
                                });
                        }}
                        unit={getAbbreviation(pointAirflowToNext.id)}
                        disabled={!lineToNext}
                        enableHotjar={true}
                    />
                </Container>
                <Footer>
                    {!canAddPoint.current && <Label>{l('points.needToEnterTwoPoints')}</Label>}
                    <Button
                        label={isEditing ? l('points.edit') : l('points.add')}
                        onClick={() => {
                            onClick();
                            close();
                        }}
                        disabled={!canAddPoint.current}
                        testid='addPointButton'
                    />
                </Footer>
            </form>
        </Overlay>
    );
};
