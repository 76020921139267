import {
    getMoistureFromTemperatureAndEnthalpy,
    getTemperatureFromHumidityAndEnthalpy,
    getTemperatureFromMoistureAndEnthalpy,
    getEnthalpyFromTemperatureAndMoisture,
    getTemperatureFromMoistureAndHumidity,
    getMoistureFromTemperatureAndHumidity,
    getEnthalpyFromMoistureAndHumidity
} from '../DiagramContainer.functions';
import { UNIT_ID, GRAPHS, DEFAULT_AXIS_VALUES, AXIS_MIN_MAX_VALUES, COLORS } from '../../lib/constants';
import { getFormattedValue } from '../../lib/utility/helpFunctions';
import { getDefaultUnit, getCultureUnit, convert, convertAndFormatToCultureUnit, getAbbreviationByProperty } from '@swegon-core/swegon.conversions.javascript';

const isValidAxisValue = value => {
    return value !== null && value !== undefined && !isNaN(value) && value !== '';
};

export const getScale = (points, userAxisValues) => {
    if (userAxisValues && Object.values(userAxisValues).some(v => isValidAxisValue(v))) {
        const scale = {
            minMoisture: isValidAxisValue(userAxisValues.minMoisture)
                ? userAxisValues.minMoisture
                : DEFAULT_AXIS_VALUES.minMoisture,
            maxMoisture: isValidAxisValue(userAxisValues.maxMoisture)
                ? userAxisValues.maxMoisture
                : DEFAULT_AXIS_VALUES.maxMoisture,
            minTemperature: isValidAxisValue(userAxisValues.minTemperature)
                ? userAxisValues.minTemperature
                : DEFAULT_AXIS_VALUES.minTemperature,
            maxTemperature: isValidAxisValue(userAxisValues.maxTemperature)
                ? userAxisValues.maxTemperature
                : DEFAULT_AXIS_VALUES.maxTemperature
        };
        return scale;
    }

    if (!points?.length) {
        return DEFAULT_AXIS_VALUES;
    }

    const temperatures = points.map(point => point.values.pointTemperature.value);
    const moistureContents = points.map(point => point.values.moistureContent.value);

    const margin = 5;

    const moistureContentsFromPoints = Math.max(...moistureContents) > AXIS_MIN_MAX_VALUES.MOISTURE_CONTENT_MAX ? AXIS_MIN_MAX_VALUES.MOISTURE_CONTENT_MAX : Math.max(...moistureContents);
    const minTemperatureFromPoints = Math.min(...temperatures) < AXIS_MIN_MAX_VALUES.TEMPERATURE_MIN ? AXIS_MIN_MAX_VALUES.TEMPERATURE_MIN : Math.min(...temperatures);
    const maxTemperatureFromPoints = Math.max(...temperatures) > AXIS_MIN_MAX_VALUES.TEMPERATURE_MAX ? AXIS_MIN_MAX_VALUES.TEMPERATURE_MAX : Math.max(...temperatures);

    const maxMoisture = moistureContentsFromPoints + margin;
    const minTemperature = minTemperatureFromPoints - margin;
    const maxTemperature = maxTemperatureFromPoints + margin;

    // Round the axis values in intervals of 5
    const interval = 5;
    const roundedMaxMoisture =
        maxMoisture > DEFAULT_AXIS_VALUES.maxMoisture
            ? Math.floor(Math.round(maxMoisture) / interval) * interval
            : DEFAULT_AXIS_VALUES.maxMoisture;
    const roundedMinTemperature =
        minTemperature < DEFAULT_AXIS_VALUES.minTemperature
            ? Math.floor(Math.round(minTemperature) / interval) * interval
            : DEFAULT_AXIS_VALUES.minTemperature;
    const roundedMaxTemperature =
        maxTemperature > DEFAULT_AXIS_VALUES.maxTemperature
            ? Math.ceil(Math.round(maxTemperature) / interval) * interval
            : DEFAULT_AXIS_VALUES.maxTemperature;

    if (userAxisValues && Object.values(userAxisValues).some(v => isValidAxisValue(v))) {
        const scale = {
            minMoisture: isValidAxisValue(userAxisValues.minMoisture)
                ? userAxisValues.minMoisture
                : DEFAULT_AXIS_VALUES.minMoisture,
            maxMoisture: isValidAxisValue(userAxisValues.maxMoisture)
                ? userAxisValues.maxMoisture
                : roundedMaxMoisture,
            minTemperature: isValidAxisValue(userAxisValues.minTemperature)
                ? userAxisValues.minTemperature
                : roundedMinTemperature,
            maxTemperature: isValidAxisValue(userAxisValues.maxTemperature)
                ? userAxisValues.maxTemperature
                : roundedMaxTemperature
        };
        return scale;
    }

    return {
        minMoisture: DEFAULT_AXIS_VALUES.minMoisture,
        maxMoisture:
            roundedMaxMoisture > DEFAULT_AXIS_VALUES.maxMoisture
                ? roundedMaxMoisture
                : DEFAULT_AXIS_VALUES.maxMoisture,
        minTemperature:
            roundedMinTemperature < DEFAULT_AXIS_VALUES.minTemperature
                ? roundedMinTemperature
                : DEFAULT_AXIS_VALUES.minTemperature,
        maxTemperature:
            roundedMaxTemperature > DEFAULT_AXIS_VALUES.maxTemperature
                ? roundedMaxTemperature
                : DEFAULT_AXIS_VALUES.maxTemperature
    };
};

export const showLabel = (minValue, maxValue, currentValue, interval, currentXValue = null) => {
    if (currentXValue !== null && minValue > currentXValue) {
        return false;
    }
    const range = maxValue - minValue;
    const showLabel = range <= 20 ? currentValue % 1 === 0 : currentValue % interval === 0;
    return showLabel;
};

export const getRelativeHumidityLineDatasets = (atmPressure, scale, typeOfGraph) => {
    const { maxMoisture, maxTemperature } = scale;
    const curvedLineDatasets = [];
    const labels = ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100'];
    for (let j = 10; j <= 100; j += 10) {
        const curvedLines = [];
        for (let moisture = 0; moisture <= maxMoisture; moisture += 0.04) {
            const temperature = getTemperatureFromMoistureAndHumidity(moisture, j, atmPressure);
            const xValue = typeOfGraph === GRAPHS.CIBS ? temperature : moisture;
            const yValue = typeOfGraph === GRAPHS.CIBS ? moisture : temperature;
            curvedLines.push({ x: xValue, y: yValue });

            // No need to add data thats outside of the graph
            if (moisture > maxMoisture || temperature > maxTemperature) {
                break;
            }
        }

        const curvedLineDataset = {
            data: curvedLines,
            datalabels: {
                display: function (context) {
                    const showLabel = context.dataIndex === context.dataset.data.length - 1;
                    return showLabel;
                },
                formatter: function (value, context) {
                    return `${context.dataset.datalabels.labels[labels.indexOf(j.toString())]}%`;
                },
                labels: labels,
                anchor: 'center',
                align: 'center',
                rotation: 30
            },
            // yAxisID and xAxisID binds the dataset to the left y axis and top x axis
            yAxisID: 'temperature-axis',
            xAxisID: 'moisture-axis',
            borderColor: COLORS.blue,
            borderWidth: 1,
            pointHitRadius: 0,
            pointHoverRadius: 0,
            tension: 1
        };
        curvedLineDatasets.push(curvedLineDataset);
    }
    return curvedLineDatasets;
};

const getMean = array => {
    return array.reduce((total, current) => total + current) / array.length;
};

// Get the first and last enthalpy value along the 100%-humidity line.
// This is considered the 'available range' where the title can be placed
const getEnthalpyTitleRange = (minTemperature, maxMoisture, atmPressure, lineInterval) => {
    const firstValue =
        Math.round(
            getEnthalpyFromMoistureAndHumidity(
                getMoistureFromTemperatureAndHumidity(minTemperature, 100, atmPressure),
                100,
                atmPressure
            ) / lineInterval
        ) * lineInterval;
    const lastValue =
        Math.round(
            getEnthalpyFromMoistureAndHumidity(maxMoisture, 100, atmPressure) / lineInterval
        ) * lineInterval;
    const meanValue = Math.round(getMean([firstValue, lastValue]) / lineInterval) * lineInterval;

    return {
        start: firstValue,
        end: lastValue,
        mean: meanValue
    };
};

export const showEnthalpyTitle = (minTemperature, maxMoisture, atmPressure, lineInterval = 5) => {
    const { start, end } = getEnthalpyTitleRange(
        minTemperature,
        maxMoisture,
        atmPressure,
        lineInterval
    );
    const span = end - start;
    const threshold = 15;
    return span >= threshold;
};

export const getKLineDatasets = (
    atmPressure,
    l,
    scale,
    typeOfGraph,
    locale
) => {
    const kLineDatasets = [];
    const kLineLabelDatasets = [];
    const kLineTitle = [];
    const { minMoisture, maxMoisture, minTemperature, maxTemperature } = scale;

    const labelInterval = 10;
    // Get max enthalpy and round to closest tens
    const roundedMaxEnthalpy =
        Math.round(
            getEnthalpyFromTemperatureAndMoisture(maxTemperature, maxMoisture) / labelInterval
        ) * labelInterval;

    const lineInterval = 5;
    const lineStart = minTemperature + lineInterval;
    const lineEnd = roundedMaxEnthalpy - lineInterval;
    for (let enthalpy = lineStart; enthalpy <= lineEnd; enthalpy += lineInterval) {
        let kLines = [];
        let kLinesLabels = [];

        const firstMoistureContent = Math.max(
            getMoistureFromTemperatureAndEnthalpy(maxTemperature, enthalpy),
            0
        );
        const secondMoistureContent = getMoistureFromTemperatureAndEnthalpy(
            Math.max(
                getTemperatureFromHumidityAndEnthalpy(100, enthalpy, atmPressure),
                minTemperature
            ),
            enthalpy
        );
        const firstTemperature = getTemperatureFromMoistureAndEnthalpy(
            Math.max(getMoistureFromTemperatureAndEnthalpy(maxTemperature, enthalpy), 0),
            enthalpy
        );
        const secondTemperature = Math.max(
            getTemperatureFromHumidityAndEnthalpy(100, enthalpy, atmPressure),
            minTemperature
        );

        const xValue1 = typeOfGraph === GRAPHS.CIBS ? firstTemperature : firstMoistureContent;
        const yValue1 = typeOfGraph === GRAPHS.CIBS ? firstMoistureContent : firstTemperature;
        const xValue2 = typeOfGraph === GRAPHS.CIBS ? secondTemperature : secondMoistureContent;
        const yValue2 = typeOfGraph === GRAPHS.CIBS ? secondMoistureContent : secondTemperature;

        kLines.push({ x: xValue1, y: yValue1 });
        kLines.push({ x: xValue2, y: yValue2 });

        if (secondMoistureContent <= maxMoisture) {
            const offset = 0.5;
            const newTemperature = getTemperatureFromMoistureAndEnthalpy(
                secondMoistureContent + offset,
                enthalpy
            );
            const newMoisture = getMoistureFromTemperatureAndEnthalpy(newTemperature, enthalpy);
            typeOfGraph === GRAPHS.CIBS
                ? kLinesLabels.push({ x: newTemperature, y: newMoisture })
                : kLinesLabels.push({ x: newMoisture, y: newTemperature });
        } else {
            const offset = 1;
            const newTemperature = getTemperatureFromMoistureAndEnthalpy(
                maxMoisture - offset,
                enthalpy
            );
            const newMoisture = getMoistureFromTemperatureAndEnthalpy(newTemperature, enthalpy);

            // Don't add labels that would appear outside of graph
            if (newTemperature > maxTemperature || newMoisture > maxMoisture) continue;

            typeOfGraph === GRAPHS.CIBS
                ? kLinesLabels.push({ x: newTemperature, y: newMoisture })
                : kLinesLabels.push({ x: newMoisture, y: newTemperature });
        }

        const kLineDataset = {
            data: kLines,
            datalabels: {
                display: false
            },
            yAxisID: 'temperature-axis',
            xAxisID: 'moisture-axis',
            borderWidth: enthalpy % labelInterval === 0 ? 2 : 0.5,
            hitRadius: 0
        };

        const currentXValue = typeOfGraph === GRAPHS.CIBS ? yValue2 : xValue2;
        const kLineLabelDataset = {
            data: kLinesLabels,
            datalabels: {
                display: true,
                formatter: function () {
                    if (
                        showLabel(minMoisture, maxMoisture, enthalpy, labelInterval, currentXValue)
                    ) {
                        return getValue(enthalpy, UNIT_ID.enthalpy, locale);
                    }
                    return '';
                },
                rotation: -30
            },
            yAxisID: 'temperature-axis',
            xAxisID: 'moisture-axis',
            hitRadius: 0
        };

        const { mean } = getEnthalpyTitleRange(
            minTemperature,
            maxMoisture,
            atmPressure,
            lineInterval
        );
        const showLabelInsideGraph = showEnthalpyTitle(
            minTemperature,
            maxMoisture,
            atmPressure,
            lineInterval
        );
        // Only add a title label for the line in the middle
        if (enthalpy === mean && showLabelInsideGraph) {
            const coordinates = [{ x: xValue2, y: yValue2 }];
            const kLineTitleDataset = {
                data: coordinates,
                datalabels: {
                    display: true,
                    formatter: function () {
                        return `${l('quantities.enthalpy')}, i, (${getAbbreviationByProperty(
                            UNIT_ID.enthalpy,
                            locale
                        )})`;
                    },
                    align: typeOfGraph === GRAPHS.CIBS ? '210' : '45',
                    offset: 20,
                    rotation: -30
                },
                xAxisID: 'moisture-axis',
                yAxisID: 'temperature-axis',
                hitRadius: 0
            };
            kLineTitle.push(kLineTitleDataset);
        }

        kLineLabelDatasets.push(kLineLabelDataset);
        kLineDatasets.push(kLineDataset);
    }
    return [...kLineDatasets, ...kLineLabelDatasets, ...kLineTitle];
};

export const getMoistureContentLines = (atmPressure, scale, typeOfGraph) => {
    const datasets = [];
    const { minMoisture, maxMoisture, maxTemperature } = scale;

    for (let moisture = minMoisture; moisture <= maxMoisture; moisture += 1) {
        let lines = [];
        const temperature = getTemperatureFromMoistureAndHumidity(moisture, 100, atmPressure);

        const xValue1 = typeOfGraph === GRAPHS.CIBS ? maxTemperature : moisture;
        const yValue1 = typeOfGraph === GRAPHS.CIBS ? moisture : maxTemperature;
        const xValue2 = typeOfGraph === GRAPHS.CIBS ? temperature : moisture;
        const yValue2 = typeOfGraph === GRAPHS.CIBS ? moisture : temperature;

        lines.push({ x: xValue1, y: yValue1 });
        lines.push({ x: xValue2, y: yValue2 });

        const dataset = {
            data: lines,
            datalabels: {
                display: false
            },
            borderWidth: moisture % 5 === 0 ? 3 : 0.5,
            xAxisID: 'moisture-axis',
            yAxisID: 'temperature-axis',
            hitRadius: 0
        };

        datasets.push(dataset);
    }
    return [...datasets];
};

export const geTemperatureLines = (atmPressure, scale, typeOfGraph) => {
    const datasets = [];
    const { minMoisture, minTemperature, maxTemperature } = scale;

    for (let temperature = minTemperature; temperature <= maxTemperature; temperature += 1) {
        let lines = [];
        const moisture = getMoistureFromTemperatureAndHumidity(temperature, 100, atmPressure);
        const xValue1 = typeOfGraph === GRAPHS.CIBS ? temperature : minMoisture;
        const yValue1 = typeOfGraph === GRAPHS.CIBS ? minMoisture : temperature;
        const xValue2 = typeOfGraph === GRAPHS.CIBS ? temperature : moisture;
        const yValue2 = typeOfGraph === GRAPHS.CIBS ? moisture : temperature;

        lines.push({ x: xValue1, y: yValue1 });
        lines.push({ x: xValue2, y: yValue2 });

        const dataset = {
            data: lines,
            datalabels: {
                display: false
            },
            borderWidth: temperature % 5 === 0 ? 3 : 0.5,
            xAxisID: 'moisture-axis',
            yAxisID: 'temperature-axis',
            hitRadius: 0
        };
        datasets.push(dataset);
    }
    return [...datasets];
};

const getGradient = (pointSet, chart, scale, typeOfGraph) => {
    if (pointSet.length <= 1 || !chart || !scale || typeof document === 'undefined') return;

    const sortedMoisturePoints = [...pointSet].sort(
        (a, b) => a.values.moistureContent.value - b.values.moistureContent.value
    );
    const sortedTemperaturePoints = [...pointSet].sort(
        (a, b) => a.values.pointTemperature.value - b.values.pointTemperature.value
    );
    const sortedColors = sortedMoisturePoints.map(point => point.color);
    const { minMoisture, maxMoisture, minTemperature, maxTemperature } = scale;
    const { top, right, left, bottom } = chart.chartArea;
    if (top === bottom || right === left) return;

    const width = right - left;
    const height = bottom - top;

    const minPointMoisture = sortedMoisturePoints[0].values.moistureContent.value;
    const maxPointMoisture =
        sortedMoisturePoints[sortedMoisturePoints.length - 1].values.moistureContent.value;
    const relativeMinMoisture = (minPointMoisture - minMoisture) / (maxMoisture - minMoisture);
    const relativeMaxMoisture = (maxPointMoisture - minMoisture) / (maxMoisture - minMoisture);
    const minX =
        typeOfGraph === GRAPHS.CIBS
            ? (1 - relativeMinMoisture) * height + top
            : relativeMinMoisture * width + left;
    const maxX =
        typeOfGraph === GRAPHS.CIBS
            ? (1 - relativeMaxMoisture) * height + top
            : relativeMaxMoisture * width + left;

    const minPointTemperature = sortedTemperaturePoints[0].values.pointTemperature.value;
    const maxPointTemperature =
        sortedTemperaturePoints[sortedTemperaturePoints.length - 1].values.pointTemperature.value;
    const relativeMinTemperature =
        (minPointTemperature - minTemperature) / (maxTemperature - minTemperature);
    const relativeMaxTemperature =
        (maxPointTemperature - minTemperature) / (maxTemperature - minTemperature);
    const minY =
        typeOfGraph === GRAPHS.CIBS
            ? relativeMinTemperature * width + left
            : (1 - relativeMinTemperature) * height + top;
    const maxY =
        typeOfGraph === GRAPHS.CIBS
            ? relativeMaxTemperature * width + left
            : (1 - relativeMaxTemperature) * height + top;

    const ctx = document.getElementById('graph').getContext('2d');
    const moistureDifference = Math.abs(maxX - minX);
    const temperatureDifference = Math.abs(maxY - minY);
    if (moistureDifference >= temperatureDifference) {
        var gradient =
            typeOfGraph === GRAPHS.CIBS
                ? ctx.createLinearGradient(0, minX, 0, maxX)
                : ctx.createLinearGradient(minX, 0, maxX, 0);
        sortedColors.forEach((color, index) => {
            const currentMoisture = sortedMoisturePoints[index].values.moistureContent.value;
            const currentX =
                (currentMoisture - minPointMoisture) / (maxPointMoisture - minPointMoisture);
            gradient.addColorStop(isNaN(currentX) ? 0 : currentX, color);
        });
    } else {
        var gradient =
            typeOfGraph === GRAPHS.CIBS
                ? ctx.createLinearGradient(minY, 0, maxY, 0)
                : ctx.createLinearGradient(0, minY, 0, maxY);
        sortedColors.forEach((color, index) => {
            const currentTemperature = sortedMoisturePoints[index].values.pointTemperature.value;
            const currentY =
                (currentTemperature - minPointTemperature) /
                (maxPointTemperature - minPointTemperature);
            gradient.addColorStop(isNaN(currentY) ? 0 : currentY, color);
        });
    }

    return gradient;
};

export const getPointDatasets = (points, l, typeOfGraph, chart, scale) => {
    if (points.length <= 0) {
        return [];
    }
    const pointsDataSets = [];
    let pointData = [];
    for (var i = 0; i < points.length; i++) {
        const moisture = points[i].values.moistureContent.value;
        const temperature = points[i].values.pointTemperature.value;
        const xValue = typeOfGraph === GRAPHS.CIBS ? temperature : moisture;
        const yValue = typeOfGraph === GRAPHS.CIBS ? moisture : temperature;
        pointData.push({
            x: xValue,
            y: yValue,
            name: points[i].name,
            prefix: points[i].prefix,
            relativeHumidity: points[i].values.relativeHumidity.value,
            enthalpy: points[i].values.enthalpy.value
        });

        const pointSet = points.filter(point => point.prefix[0] === points[i].prefix[0]);
        const colorSet = pointSet.map(point => point.color);

        if (!points[i].lineToNext || i === points.length - 1) {
            const pointDataset = {
                labels: [
                    l('quantities.temperature'),
                    l('quantities.relativeHumidity'),
                    l('quantities.moistureContent'),
                    l('quantities.enthalpy')
                ],
                data: pointData,
                yAxisID: 'temperature-axis',
                xAxisID: 'moisture-axis',
                borderWidth: 3,
                borderColor: getGradient(pointSet, chart, scale, typeOfGraph) || points[i].color,
                pointRadius: 6,
                pointBackgroundColor: colorSet,
                pointBorderColor: 'black',
                pointHitRadius: 6,
                pointBorderWidth: 1,
                pointHoverRadius: 7,
                pointHoverBorderWidth: 1.5,
                datalabels: {
                    labels: {
                        value: {
                            formatter: function (value, ctx) {
                                return value.prefix;
                            }
                        }
                    },
                    clip: true,
                    anchor: 'center',
                    offset: 10,
                    align: 'top',
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                }
            };
            pointsDataSets.push(pointDataset);
            pointData = [];
        }
    }
    return pointsDataSets;
};

export const getConvertedValue = (value, propertyName, locale, convertBack = false, format = true) => {
    if (isNaN(value) || !propertyName) return;

    if (value === '') return '';

    if (!format) {
        if (convertBack) {
            const defaultUnit = getDefaultUnit(propertyName);
            const cultureUnit = getCultureUnit(propertyName, locale);
            const convertedValue = convert(value, cultureUnit, defaultUnit, true);
            return convertedValue;
        } else {
            const defaultUnit = getDefaultUnit(propertyName);
            const cultureUnit = getCultureUnit(propertyName, locale);
            const convertedValue = convert(value, defaultUnit, cultureUnit);
            return convertedValue;
        }
    } else {
        if (convertBack) {
            const convertedAndFormatted = convertAndFormatToDefaultUnit(
                value,
                propertyName,
                locale
            ).formattedValue;
            return convertedAndFormatted;
        } else {
            const convertedAndFormatted = convertAndFormatToCultureUnit(
                value,
                propertyName,
                locale
            ).formattedValue;
            return convertedAndFormatted;
        }
    }
};

export const getValue = (value, id, locale) => {
    const convertedValue = getConvertedValue(value, id, locale);
    return getFormattedValue(convertedValue);
};

export const getMixingsDatasets = (mixings, typeOfGraph, isExternalApplication) => {
    // For now, mixings should only be visible in AHUD
    if (isExternalApplication) return [];

    if (!mixings || !mixings.length) return [];

    const datasets = [];
    mixings.forEach(mixing => {
        const moisture1 = mixing.from.values.moistureContent.value;
        const temperature1 = mixing.from.values.pointTemperature.value;
        const moisture2 = mixing.to.values.moistureContent.value;
        const temperature2 = mixing.to.values.pointTemperature.value;
        const xValue1 = typeOfGraph === GRAPHS.CIBS ? temperature1 : moisture1;
        const yValue1 = typeOfGraph === GRAPHS.CIBS ? moisture1 : temperature1;
        const xValue2 = typeOfGraph === GRAPHS.CIBS ? temperature2 : moisture2;
        const yValue2 = typeOfGraph === GRAPHS.CIBS ? moisture2 : temperature2;

        const lines = [
            { x: xValue1, y: yValue1 },
            { x: xValue2, y: yValue2 }
        ];

        const dataset = {
            data: lines,
            datalabels: {
                display: false
            },
            borderWidth: 3,
            borderDash: [10, 8],
            borderColor: COLORS.purple,
            xAxisID: 'moisture-axis',
            yAxisID: 'temperature-axis',
            hitRadius: 0
        };

        datasets.push(dataset);
    });

    return [...datasets];
};
