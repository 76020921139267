export const getTranslations = locale => {
    return fetch(`/translations.${locale.toLowerCase()}.lang.json`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not OK');
            }
            return response.json();
        })
        .catch(error => {
            console.error('There was a problem with fetching translations:', error);
        });
};

export const getLanguages = () => {
    return new Promise((resolve, reject) => {
        resolve([
            {
                name: 'English',
                value: 'en-GB'
            },
            {
                name: 'Svenska',
                value: 'sv-SE'
            }
        ]);
    });
};
