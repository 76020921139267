import { UNIT_ID } from '../lib/constants';

// Calculate water vapour saturation pressure(hPa) given temperature(C)
const getSaturationPressureFromTemperature = T => {
    const T_K = T + 273.15; // Convert to Kelvin
    const Tc = 647.096; // K
    const Pc = 220640; // hPa
    const C1 = -7.85951783; // Constant
    const C2 = 1.84408259; // Constant
    const C3 = -11.7866497; // Constant
    const C4 = 22.6807411; // Constant
    const C5 = -15.9618719; // Constant
    const C6 = 1.80122502; // Constant

    const ύ = 1 - T_K / Tc;
    const Pws =
        Math.exp(
            (Tc / T_K) *
                (C1 * ύ +
                    C2 * Math.pow(ύ, 1.5) +
                    C3 * Math.pow(ύ, 3) +
                    C4 * Math.pow(ύ, 3.5) +
                    C5 * Math.pow(ύ, 4) +
                    C6 * Math.pow(ύ, 7.5))
        ) * Pc;

    return Pws;
};

// Calculate saturation pressure(hPa) given moisture content(g/kg) and atmospheric pressure(hPa)
const getSaturationPressureFromMoisture = (_X, _atmPressure) => {
    const k = 0.621964713077499; //Ratio of molar masses
    const atmPressure = _atmPressure * 100; // Convert hPa to Pa
    const X = _X / 1000; // Convert g/kg to kg/kg

    const Pw = (atmPressure * X) / (X + k) / 100;
    return Pw;
};

// Calculate saturation pressure(hPa) given enthalpy(kJ/kg)
const getSaturationPressureFromEnthalpy = _h => {
    const h = _h * 1000; // Convert from kJ/kg to J/kg
    const c = [
        1.7285e-23, 2.0574e-18, 3.357e-14, 0.0056848e-6, 0.6621e-3, 5.76, 3.274109e-25, 3.07601,
        10e-31, 1.458599e-19, 3.608144e-14, 0.00569071e-6, 0.684782e-3, 5.978
    ];

    const Pw =
        h < 10
            ? ((((c[0] * h + c[1]) * h + c[2]) * h - c[3]) * h + c[4]) * h - c[5]
            : (((((c[6] - c[7] * c[8] * h) * h - c[9]) * h + c[10]) * h - c[11]) * h + c[12]) * h -
              c[13];

    return Pw;
};

// Calculate temperature dewpoint(C) given moisture content(g/kg), relative humidity(%) and atmospheric pressure(hPa)
export const getTemperatureDewPointFromMoistureAndHumidity = (X, RH, atmPressure) => {
    const A = 6.116441; // Constant
    const m = 7.591386; // Constant
    const Tn = 240.7263; // K

    const Pw = getSaturationPressureFromMoisture(X, atmPressure);
    const Pws = (Pw / RH) * 100;
    const Td = Tn / (m / Math.log10(Math.max(Math.pow(10, -5), Pws / A)) - 1);
    return Td;
};

// Calculate temperature(C) given relative humidity(%) and enthalpy(kJ/kg)
const getTemperatureDewPointFromHumidityAndEnthalpy = (RH, h) => {
    const A = 6.116441; // Constant
    const m = 7.591386; // Constant
    const Tn = 240.7263; // K

    const Pw = getSaturationPressureFromEnthalpy(h);
    const Pws = (Pw / RH) * 100;
    const Td = Tn / (m / Math.log10(Math.max(Math.pow(10, -5), Pws / A)) - 1);
    return Td;
};

// Calculate temperature(C) given relative humidity(%), enthalpy(kJ/kg) and atmospheric pressure(hPa)
export const getTemperatureFromHumidityAndEnthalpy = (RH, h, atmPressure) => {
    let T = getTemperatureDewPointFromHumidityAndEnthalpy(RH, h);
    let X = getMoistureFromTemperatureAndHumidity(T, RH, atmPressure);
    for (let i = 0; i < 100; i++) {
        const h2 = getEnthalpyFromTemperatureAndHumidity(T, RH, atmPressure);
        if (Math.abs(h - h2) < 0.0005) {
            return T;
        } else {
            T = (T + getTemperatureFromMoistureAndEnthalpy(X, h)) / 2;
            X = (X + getMoistureFromTemperatureAndHumidity(T, RH, atmPressure)) / 2;
        }
    }
    return T;
};

// Calculate temperature(C) given moisture content(g/kg), relative humidity(%) and atmospheric pressure(hPa)
export const getTemperatureFromMoistureAndHumidity = (X, RH, atmPressure) => {
    const T = getTemperatureDewPointFromMoistureAndHumidity(X, RH, atmPressure);
    return T;
};

// Calculate temperature(C) given moisture content(g/kg) and enthalpy(kJ/kg)
export const getTemperatureFromMoistureAndEnthalpy = (X, h) => {
    const T = (h - 2.5 * X) / (1.01 + 0.00189 * X);
    return T;
};

// Calculate relative humidity(%) given temperature(C), moisture content(g/kg) and atmospheric pressure(hPa)
export const getHumidityFromTemperatureAndMoisture = (T, X, atmPressure) => {
    const B = 621.9907; // Constant
    const Ptot = atmPressure;
    const Pws = getSaturationPressureFromTemperature(T);
    const RH = (100 * X * Ptot) / (Pws * (B + X));
    return RH;
};

// Calculate moisture content(g/kg) given temperature(C), relative humidity(%) and atmospheric pressure(hPa)
export const getMoistureFromTemperatureAndHumidity = (T, RH, atmPressure) => {
    const B = 621.9907; // Constant
    const Ptot = atmPressure;
    const Pws = getSaturationPressureFromTemperature(T);
    const Pw = (Pws * RH) / 100;
    const X = (B * Pw) / (Ptot - Pw);
    return X;
};

// Calculate moisture content(g/kg) given temperature(C) and enthalpy(kJ/kg)
export const getMoistureFromTemperatureAndEnthalpy = (T, h) => {
    const X = (h - 1.01 * T) / (0.00189 * T + 2.5);
    return X;
};

// Calculate enthalpy(kJ/kg) given temperature(C), relative humidity(%) and atmospheric pressure(hPa)
export const getEnthalpyFromTemperatureAndHumidity = (T, RH, atmPressure) => {
    const X = getMoistureFromTemperatureAndHumidity(T, RH, atmPressure);
    const h = T * (1.01 + 0.00189 * X) + 2.5 * X;
    return h;
};

// Calculate enthalpy(kJ/kg) given moisture content(g/kg), relative humidity(%) and atmospheric pressure(hPa)
export const getEnthalpyFromMoistureAndHumidity = (X, RH, atmPressure) => {
    const T = getTemperatureFromMoistureAndHumidity(X, RH, atmPressure);
    const h = T * (1.01 + 0.00189 * X) + 2.5 * X;
    return h;
};

// Calculate enthalpy(kJ/kg) given temperature(C) and moisture content(g/kg)
export const getEnthalpyFromTemperatureAndMoisture = (T, X) => {
    const h = T * (1.01 + 0.00189 * X) + 2.5 * X;
    return h;
};

// Calculate density(kg/m3) given temperature(C) and atmospheric pressure(hPa)
export const getDensity = (T, atmPressure) => {
    const gasConstant = 287.05;
    const T_K = T + 273.15;
    const Pa = atmPressure * 100;

    return Number(Pa / (gasConstant * T_K));
};

// Calculate power(W) given airflow(m3/s), density(kg/m3), and difference in enthalpy(kJ/kg)
export const getPower = (airflow, density, firstEnthalpy, secondEnthalpy) => {
    const power = airflow * density * (secondEnthalpy - firstEnthalpy);
    return power;
};

// Calculate sensible power(W) given power(W), airflow(m3/s), density(kg/m3), and difference in enthalpy(kJ/kg)
export const getSensiblePower = (
    airflow,
    density,
    firstMoistureContent,
    secondMoistureContent,
    power
) => {
    const sensiblePower =
        power - airflow * density * (secondMoistureContent - firstMoistureContent) * 2.5;
    return sensiblePower;
};

// Calculate amount of water(l/min) given airflow(m3/s), density(kg/m3), and difference in moisture content(g/kg)
export const getWater = (airflow, density, firstMoistureContent, secondMoistureContent) => {
    const water = (airflow * density * (secondMoistureContent - firstMoistureContent) * 60) / 1000;
    return water;
};

export const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
};

// Calculate steam mass fraction(kg/kg) given saturation pressure(Pa), relative humidity(%) and atmospheric pressure(hPa)
const getAbsoluteHumidity = (saturationPressure, relativeHumidity, atmPressure) => {
    const k_mair = 0.6219647130774989; // "Ratio of molar weights"
    const absoluteHumidity =
        (relativeHumidity * saturationPressure * k_mair) /
        Math.max(1e-5, atmPressure - (relativeHumidity + 1e-6) * saturationPressure);

    return absoluteHumidity;
};

// Calculate saturation pressure(Pa) given drybulb temperature(C)
const getSaturationPressure = dryTemperature => {
    const T1 = 0; // First support point
    const T2 = 50; // Second support point
    const A = [611.4742, 611.6441, 600.4918];
    const m = [9.778707, 7.591386, 7.337936];
    const Tn = [273.1466, 240.7263, 229.3975];

    const saturationPressure =
        dryTemperature > T2
            ? A[2] * Math.pow(10, (m[2] * dryTemperature) / (dryTemperature + Tn[2]))
            : dryTemperature > T1
            ? A[1] * Math.pow(10, (m[1] * dryTemperature) / (dryTemperature + Tn[1]))
            : A[0] * Math.pow(10, (m[0] * dryTemperature) / (dryTemperature + Tn[0]));

    return saturationPressure;
};

// Calculate wetbulb temperature(C) given drybulb temperature(C), relative humidity(%) and atmospheric pressure(hPa)
const relativeHumidityToWetBulb = (dryTemperature, relativeHumidity, atmPressure) => {
    const saturationPressure = getSaturationPressure(dryTemperature);
    const absoluteHumidity = getAbsoluteHumidity(saturationPressure, relativeHumidity, atmPressure);
    const s_1 = dryTemperature + (absoluteHumidity * (2500 + 1.86 * dryTemperature)) / 1.005;

    const wetBulbTemperature =
        s_1 > 9.389
            ? s_1 > 65
                ? Math.pow(
                      Math.max(
                          0,
                          Math.pow(7.7813 - 18.6 * absoluteHumidity, 2) +
                              8.925 * dryTemperature +
                              absoluteHumidity * (22200 + 16.52 * dryTemperature) -
                              420.4
                      ),
                      0.5
                  ) +
                  7.7813 -
                  18.6 * absoluteHumidity
                : Math.pow(
                      Math.max(
                          0,
                          Math.pow(17.03 + 48.63 * absoluteHumidity, 2) +
                              23.33 * dryTemperature +
                              absoluteHumidity * (58026 + 43.17 * dryTemperature) -
                              227.7
                      ),
                      0.5
                  ) -
                  17.03 -
                  48.63 * absoluteHumidity
            : Math.pow(
                  Math.max(
                      0,
                      Math.pow(61.32 + 75 * absoluteHumidity, 2) +
                          71.43 * dryTemperature +
                          absoluteHumidity * (201350 + 132.2 * dryTemperature) -
                          741.4
                  ),
                  0.5
              ) -
              61.32 -
              75 * absoluteHumidity;

    return wetBulbTemperature;
};

// Calculate wetbulb temperature(C) given drybulb temperature(C), relative humidity(%) and atmospheric pressure(hPa)
export const getWetBulb = (dryTemperature, relativeHumidity, atmPressure = 1013.25) => {
    if (
        isNaN(dryTemperature) ||
        dryTemperature === null ||
        isNaN(relativeHumidity) ||
        relativeHumidity === null
    ) {
        return;
    }

    const pressure = atmPressure * 100; // Convert to pascal
    const relativeHumidityPercentage = relativeHumidity / 100;

    const wetBulbTemperature = relativeHumidityToWetBulb(
        dryTemperature,
        relativeHumidityPercentage,
        pressure
    );
    const wetBulbTemperature100 = relativeHumidityToWetBulb(dryTemperature, 1, pressure);

    const result = dryTemperature + Math.min(0, wetBulbTemperature - wetBulbTemperature100);

    return result;
};

// Calculate relative humidity(%) given drybulb temperature(C), wetbulb temperature(C) and atmospheric pressure(hPa)
export const getRelativeHumidity = (dryTemperature, wetTemperature, atmPressure = 1013.25) => {
    if (
        isNaN(dryTemperature) ||
        dryTemperature === null ||
        isNaN(wetTemperature) ||
        wetTemperature === null
    ) {
        return;
    }

    const pressure = atmPressure * 100; // Convert to pascal
    let absoluteHumidity = 0.02; //Absolut humidity (kg/kg)
    const deltaXw = 0.01; //Start humidity step

    const wetBulbTemperature100 = relativeHumidityToWetBulb(dryTemperature, 1, pressure);
    const saturationPressure = getSaturationPressure(dryTemperature);

    for (let i = 0; i < 16; i++) {
        const s_1 = dryTemperature + (absoluteHumidity * (2500 + 1.86 * dryTemperature)) / 1.005;

        let wetBulbTemperature =
            s_1 > 9.389
                ? s_1 > 65
                    ? Math.pow(
                          Math.max(
                              0,
                              Math.pow(7.7813 - 18.6 * absoluteHumidity, 2) +
                                  8.925 * dryTemperature +
                                  absoluteHumidity * (22200 + 16.52 * dryTemperature) -
                                  420.4
                          ),
                          0.5
                      ) +
                      7.7813 -
                      18.6 * absoluteHumidity
                    : Math.pow(
                          Math.max(
                              0,
                              Math.pow(17.03 + 48.63 * absoluteHumidity, 2) +
                                  23.33 * dryTemperature +
                                  absoluteHumidity * (58026 + 43.17 * dryTemperature) -
                                  227.7
                          ),
                          0.5
                      ) -
                      17.03 -
                      48.63 * absoluteHumidity
                : Math.pow(
                      Math.max(
                          0,
                          Math.pow(61.32 + 75 * absoluteHumidity, 2) +
                              71.43 * dryTemperature +
                              absoluteHumidity * (201350 + 132.2 * dryTemperature) -
                              741.4
                      ),
                      0.5
                  ) -
                  61.32 -
                  75 * absoluteHumidity;

        absoluteHumidity =
            wetBulbTemperature < wetTemperature + wetBulbTemperature100 - dryTemperature
                ? absoluteHumidity + deltaXw / Math.pow(2, i)
                : absoluteHumidity - deltaXw / Math.pow(2, i);
    }

    const k_mair = 0.6219647130774989; // "Ratio of molar weights"

    const relativeHumidity =
        Math.max(
            0,
            Math.min(
                1,
                pressure /
                    Math.max(
                        1e-6,
                        saturationPressure * (1 + k_mair / Math.max(1e-6, absoluteHumidity))
                    ),
                3
            )
        ) * 100;

    return relativeHumidity;
};
