export const setObjectInLocalStorage = (key, value) => {
    if (typeof window === "undefined") {
        return;
    };
    localStorage.setItem(key, value && JSON.stringify(value));
};

export const getObjectInLocalStorage = (key) => {
    if (typeof window === "undefined") {
        return;
    };
    var value = localStorage.getItem(key);
    return value && JSON.parse(value);
};
