import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: minmax(40%, 50%) minmax(40%, 50%);
    grid-template-rows: auto;
    grid-gap: 1.5em 1em;
    align-items: end;
`;
export const Name = styled.div`
    grid-column: 1/3;
`;
export const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 0.625rem;
    align-items: center;
`;
export const Label = styled.p`
    padding: 0 1em;
    font-style: italic;
`;
