export const noVersion = [0, 0, 0];

export const APPLICATION_VERSION = process.env.npm_package_version;

export const parseVersion = version => {
    if (!version) {
        return noVersion;
    }

    const versions = version.split('.').map(v => {
        var parsed = parseInt(v, 10);
        return isNaN(parsed) ? 0 : Math.max(0, parsed);
    });
    return [...noVersion.slice(0, 3 - versions.length), ...versions];
};

export const compareVersions = (v1, v2) => {
    const version1 = parseVersion(v1);
    const version2 = parseVersion(v2);

    return (
        Math.sign(version1[0] - version2[0]) ||
        Math.sign(version1[1] - version2[1]) ||
        Math.sign(version1[2] - version2[2])
    );
};
