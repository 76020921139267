import React, { useContext, useEffect } from 'react';
import { AHUD, InputField } from '@swegon-core/ui-components';
import {
    Container,
    DropdownContainer,
    PillsContainer,
    InputContainer,
    Label
} from './Settings.styles';
import { Context as TranslationContext } from '../modules/Translation/TranslationContext';
import { Context as SettingsContext } from '../modules/Settings/SettingsContext';
import { Context as UnitConversionContext } from '../modules/Application/UnitConversionContext';
import { PRINT_ORIENTATIONS, UNIT_ID, UNIT_SYSTEM, DEFAULT_AXIS_VALUES } from '../lib/constants';
import { getFormattedValue } from '../lib/utility/helpFunctions';

export const Settings = ({ isVisible, close }) => {
    const { l, languages } = useContext(TranslationContext);
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);
    const {
        locale,
        setLocale,
        printOrientation,
        setPrintOrientation,
        typeOfUnitSystem,
        setTypeOfUnitSystem,
        typeOfGraph,
        setTypeOfGraph,
        typeOfHumidity,
        setTypeOfHumidity,
        userAxisValues,
        setUserAxisValues,
        visualUserAxisValues,
        setVisualUserAxisValues
    } = useContext(SettingsContext);
    const { Overlay, Pills, ChartMollier } = AHUD;
    const printOrientations = [
        { name: l('settings.portrait'), value: PRINT_ORIENTATIONS.PORTRAIT },
        { name: l('settings.landscape'), value: PRINT_ORIENTATIONS.LANDSCAPE }
    ];

    const getValue = target => {
        if (!target) return;
        return isNaN(target.valueAsNumber) ? target.value : target.valueAsNumber;
    };

    useEffect(() => {
        setVisualUserAxisValues({
            ...visualUserAxisValues,
            minMoisture: getConversion(userAxisValues?.minMoisture, UNIT_ID.moistureContent) ?? '',
            maxMoisture: getConversion(userAxisValues?.maxMoisture, UNIT_ID.moistureContent) ?? '',
            minTemperature:
                getConversion(userAxisValues?.minTemperature, UNIT_ID.globalTemperature) ?? '',
            maxTemperature:
                getConversion(userAxisValues?.maxTemperature, UNIT_ID.globalTemperature) ?? ''
        });
    }, [typeOfUnitSystem]);

    return (
        <Overlay
            isVisible={isVisible}
            close={close}
            width={'54vw'}
            height={'54vh'}
            label={l('header.settings')}
            testid='settingsDialogue'
        >
            <Container>
                <DropdownContainer>
                    <InputField
                        label={l('settings.language')}
                        id='language'
                        type={'select'}
                        options={languages}
                        value={locale}
                        onChange={target => setLocale(target.value)}
                        testid='settingsLanguage'
                        enableHotjar={true}
                    />
                    <InputField
                        label={l('settings.printformat')}
                        id='printformat'
                        type={'select'}
                        options={printOrientations}
                        value={printOrientation}
                        onChange={target => setPrintOrientation(target.value)}
                        testid='settingsPrintFormat'
                        enableHotjar={true}
                    />
                </DropdownContainer>
                <PillsContainer>
                    <div>
                        <Pills
                            title={l('settings.typeOfUnitSystem')}
                            items={[
                                { value: 'Metric', content: 'Metric' },
                                { value: 'Imperial', content: 'Imperial' }
                            ]}
                            defaultSelection={typeOfUnitSystem}
                            onChange={value => setTypeOfUnitSystem(value)}
                            testid='settingsTypeOfUnitSystem'
                        />
                    </div>
                    <div>
                        <Pills
                            title={l('settings.typeOfGraph')}
                            items={[
                                { value: 'Mollier', content: <ChartMollier /> },
                                {
                                    value: 'CIBS',
                                    content: <ChartMollier transform='scale(-1, 1) rotate(-90)' />
                                }
                            ]}
                            defaultSelection={typeOfGraph}
                            onChange={value => setTypeOfGraph(value)}
                            testid='settingsTypeOfGraph'
                        />
                    </div>
                    <div>
                        <Pills
                            title={l('settings.typeOfHumidity')}
                            items={[
                                {
                                    value: 'relativeHumidity',
                                    content: l('settings.relativeHumidity')
                                },
                                { value: 'wetBulb', content: l('settings.wetBulb') }
                            ]}
                            defaultSelection={typeOfHumidity}
                            onChange={value => setTypeOfHumidity(value)}
                            testid='settingsTypeOfHumidity'
                        />
                    </div>
                </PillsContainer>
                <InputContainer>
                    <Label>{l('settings.axisValues')}</Label>
                    <InputField
                        label={`${l('quantities.moistureContent')} ${l('settings.min')}`}
                        id='axisValuesMinMoisture'
                        type={'number'}
                        value={
                            !Number.isNaN(visualUserAxisValues.minMoisture)
                                ? visualUserAxisValues.minMoisture
                                : ''
                        }
                        unit={getAbbreviation(UNIT_ID.moistureContent)}
                        placeholder={getFormattedValue(
                            getConversion(DEFAULT_AXIS_VALUES.minMoisture, UNIT_ID.moistureContent)
                        )}
                        step={1}
                        onChange={target =>
                            setVisualUserAxisValues({
                                ...visualUserAxisValues,
                                minMoisture: getValue(target)
                            })
                        }
                        onBlur={target => {
                            if (target.value === userAxisValues.minMoisture) return;
                            setUserAxisValues({
                                ...userAxisValues,
                                minMoisture:
                                    typeOfUnitSystem === UNIT_SYSTEM.METRIC
                                        ? getValue(target)
                                        : getConversion(
                                              getValue(target),
                                              UNIT_ID.moistureContent,
                                              true
                                          )
                            });
                        }}
                        testid='settingsMinMoistureInput'
                        enableHotjar={true}
                    />
                    <InputField
                        label={`${l('quantities.moistureContent')} ${l('settings.max')}`}
                        id='axisValuesMaxMoisture'
                        type={'number'}
                        value={
                            !Number.isNaN(visualUserAxisValues.maxMoisture)
                                ? visualUserAxisValues.maxMoisture
                                : ''
                        }
                        unit={getAbbreviation(UNIT_ID.moistureContent)}
                        placeholder={getFormattedValue(
                            getConversion(DEFAULT_AXIS_VALUES.maxMoisture, UNIT_ID.moistureContent)
                        )}
                        step={1}
                        onChange={target =>
                            setVisualUserAxisValues({
                                ...visualUserAxisValues,
                                maxMoisture: getValue(target)
                            })
                        }
                        onBlur={target => {
                            if (target.value === userAxisValues.maxMoisture) return;
                            setUserAxisValues({
                                ...userAxisValues,
                                maxMoisture:
                                    typeOfUnitSystem === UNIT_SYSTEM.METRIC
                                        ? getValue(target)
                                        : getConversion(
                                              getValue(target),
                                              UNIT_ID.moistureContent,
                                              true
                                          )
                            });
                        }}
                        testid='settingsMaxMoistureInput'
                        enableHotjar={true}
                    />
                    <InputField
                        label={`${l('settings.temperatureDryBulbShort')} ${l('settings.min')}`}
                        id='axisValuesMinTemperature'
                        type={'number'}
                        value={
                            !Number.isNaN(visualUserAxisValues.minTemperature)
                                ? visualUserAxisValues.minTemperature
                                : ''
                        }
                        unit={getAbbreviation(UNIT_ID.globalTemperature)}
                        placeholder={getFormattedValue(
                            getConversion(
                                DEFAULT_AXIS_VALUES.minTemperature,
                                UNIT_ID.globalTemperature
                            )
                        )}
                        step={1}
                        onChange={target =>
                            setVisualUserAxisValues({
                                ...visualUserAxisValues,
                                minTemperature: getValue(target)
                            })
                        }
                        onBlur={target => {
                            if (target.value === userAxisValues.minTemperature) return;
                            setUserAxisValues({
                                ...userAxisValues,
                                minTemperature:
                                    typeOfUnitSystem === UNIT_SYSTEM.METRIC
                                        ? getValue(target)
                                        : getConversion(
                                              getValue(target),
                                              UNIT_ID.globalTemperature,
                                              true
                                          )
                            });
                        }}
                        testid='settingsMinTemperatureInput'
                        enableHotjar={true}
                    />
                    <InputField
                        label={`${l('settings.temperatureDryBulbShort')} ${l('settings.max')}`}
                        id='axisValuesmaxTemperature'
                        type={'number'}
                        value={
                            !Number.isNaN(visualUserAxisValues.maxTemperature)
                                ? visualUserAxisValues.maxTemperature
                                : ''
                        }
                        unit={getAbbreviation(UNIT_ID.globalTemperature)}
                        placeholder={getFormattedValue(
                            getConversion(
                                DEFAULT_AXIS_VALUES.maxTemperature,
                                UNIT_ID.globalTemperature
                            )
                        )}
                        step={1}
                        onChange={target =>
                            setVisualUserAxisValues({
                                ...visualUserAxisValues,
                                maxTemperature: getValue(target)
                            })
                        }
                        onBlur={target => {
                            if (target.value === userAxisValues.maxTemperature) return;
                            setUserAxisValues({
                                ...userAxisValues,
                                maxTemperature:
                                    typeOfUnitSystem === UNIT_SYSTEM.METRIC
                                        ? getValue(target)
                                        : getConversion(
                                              getValue(target),
                                              UNIT_ID.globalTemperature,
                                              true
                                          )
                            });
                        }}
                        testid='settingsMaxTemperatureInput'
                        enableHotjar={true}
                    />
                </InputContainer>
            </Container>
        </Overlay>
    );
};
