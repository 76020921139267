import React, { useState, useContext, useRef, useEffect } from 'react';
import { AHUD, Delete, DragHandle, HorizontalMore, SOG_GRAY_4 } from '@swegon-core/ui-components';
import { DataString } from './DataString';
import {
    Container,
    Circle,
    Title,
    Prefix,
    Label,
    Options,
    Handle,
    Data,
    OverlayContainer
} from './Point.styles';
import { Draggable } from 'react-beautiful-dnd';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Context as ApplicationContext } from '../../modules/Application/ApplicationContext';
import { Context as UnitConversionContext } from '../../modules/Application/UnitConversionContext';
import { Context as SettingsContext } from '../../modules/Settings/SettingsContext';
import { UNIT_ID } from '../../lib/constants';
import { getWetBulb } from '../DiagramContainer.functions';
import { getFormattedValue } from '../../lib/utility/helpFunctions';
import { isWetBulbUnit } from '../../lib/utility/helpFunctions';

export const Point = ({ point, index, deletePoint, editPoint }) => {
    const { l } = useContext(TranslationContext);
    const { getAbbreviation, getConversion } = useContext(UnitConversionContext);
    const { isExternalApplication, typeOfHumidity } = useContext(SettingsContext);
    const { flowDataValues } = useContext(ApplicationContext);

    const { IconButton, Overlay, MenuItem, EditPen } = AHUD;
    const { prefix, name, values, color } = point;
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [titleWidth, setTitleWidth] = useState(200);
    const labelRef = useRef(undefined);

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const handleResize = () => {
        setTitleWidth(labelRef?.current?.clientWidth);
    };

    const getValue = values => {
        const { value, id } = values;

        if (isWetBulbUnit(id, typeOfHumidity)) {
            const pressure = flowDataValues.atmPressure.value;
            const wetBulb = getWetBulb(point.values.pointTemperature.value, value, pressure);
            return getFormattedValue(getConversion(wetBulb, UNIT_ID.pointTemperature));
        } else {
            return getFormattedValue(getConversion(value, id));
        }
    };

    const getUnit = id => {
        if (isWetBulbUnit(id, typeOfHumidity)) {
            return getAbbreviation(UNIT_ID.pointTemperature);
        } else {
            return getAbbreviation(id);
        }
    };

    const getLabel = values => {
        const { label, id } = values;

        if (isWetBulbUnit(id, typeOfHumidity)) {
            return 'quantities.wetBulb';
        } else {
            return label;
        }
    };

    useEffect(() => {
        if (typeof window === "undefined") {
            return;
        };
        setTitleWidth(labelRef?.current?.clientWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Draggable
            draggableId={`draggablePoint-${index}`}
            index={index}
            isDragDisabled={!isExternalApplication}
        >
            {(provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    data-testid={`point-${index}`}
                >
                    <Circle color={color} />
                    <Title ref={labelRef}>
                        <Prefix>{prefix}</Prefix>
                        <Label width={titleWidth * 0.8}>{name}</Label>
                    </Title>
                    <Options hide={!isExternalApplication} data-testid={`pointOptions-${index}`}>
                        <IconButton
                            tooltip={!isMenuVisible ? l('points.options') : ''}
                            onClick={toggleMenu}
                        >
                            <HorizontalMore color={SOG_GRAY_4} height={20} />
                        </IconButton>
                    </Options>
                    <Handle hide={!isExternalApplication} {...provided.dragHandleProps}>
                        <IconButton type='none'>
                            <DragHandle color={SOG_GRAY_4} />
                        </IconButton>
                    </Handle>
                    <Data>
                        {Object.entries(values).map(
                            ([key, values]) =>
                                key !== 'pointAirflowToNext' && (
                                    <DataString
                                        key={key}
                                        name={key}
                                        index={index}
                                        label={getLabel(values)}
                                        value={getValue(values)}
                                        unit={getUnit(values.id)}
                                        manuallyEntered={values.manuallyEntered}
                                    />
                                )
                        )}
                    </Data>
                    <OverlayContainer>
                        <Overlay
                            isVisible={isMenuVisible}
                            close={toggleMenu}
                            padding={'0'}
                            type={'static'}
                        >
                            <MenuItem
                                value={l('points.edit')}
                                onClick={() => {
                                    editPoint();
                                    toggleMenu();
                                }}
                                testid={'menuItemEdit'}
                            >
                                <IconButton type='none' padding={'0 1.25rem 0 0'}>
                                    <EditPen width={20} height={20} color={SOG_GRAY_4} />
                                </IconButton>
                            </MenuItem>
                            <MenuItem
                                value={l('points.delete')}
                                onClick={() => {
                                    deletePoint();
                                    toggleMenu();
                                }}
                                testid={'menuItemDelete'}
                            >
                                <IconButton type='none' padding={'0 1.25rem 0 0'}>
                                    <Delete width={20} height={20} color={SOG_GRAY_4} />
                                </IconButton>
                            </MenuItem>
                        </Overlay>
                    </OverlayContainer>
                </Container>
            )}
        </Draggable>
    );
};
