import React, { useContext } from 'react';
import { AHUD } from '@swegon-core/ui-components';
import { Point } from './Point';
import { Line } from './Line';
import { Container } from './Points.styles';
import { Droppable } from 'react-beautiful-dnd';
import { Context as TranslationContext } from '../../modules/Translation/TranslationContext';
import { Context as SettingsContext } from '../../modules/Settings/SettingsContext';
import { hasMixing, getNextPoint } from '../../lib/dataFunctions';
import { COLORS } from '../../lib/constants';

export const Points = ({ points, lines, mixings, deletePoint, toggleAddPoint }) => {
    const { l } = useContext(TranslationContext);
    const { isExternalApplication } = useContext(SettingsContext);
    const { ExpansionPanel } = AHUD;

    const hasPointAfter = point => {
        return points.indexOf(point) + 1 < points.length;
    };

    const getLineColor = key => {
        const index = parseInt(key);
        return points[index + 1].color;
    };

    return (
        <ExpansionPanel
            testid='pointsExpansionPanel'
            label={l('points.title')}
            showButton={isExternalApplication}
            tooltip={l('points.addPoint')}
            onClick={toggleAddPoint}
        >
            <Droppable droppableId='droppablePoints'>
                {(provided, snapshot) => (
                    <Container
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        isDraggingOver={snapshot.isDraggingOver}
                        data-testid='listOfPoints'
                    >
                        {Object.entries(points).map(([key, point]) => (
                            <React.Fragment key={key}>
                                <Point
                                    point={point}
                                    index={Number(key)}
                                    deletePoint={() => deletePoint(key)}
                                    editPoint={() => {
                                        toggleAddPoint(point);
                                    }}
                                />

                                {hasMixing(point, points) && !isExternalApplication && (
                                    <Line
                                        line={mixings.find(
                                            m => m.to === getNextPoint(point, points)
                                        )}
                                        index={Number(key)}
                                        color={COLORS.purple}
                                        isDashed={true}
                                    />
                                )}

                                {point.lineToNext && hasPointAfter(point) && (
                                    <Line
                                        line={lines[key]}
                                        index={Number(key)}
                                        color={getLineColor(key)}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                        {provided.placeholder}
                    </Container>
                )}
            </Droppable>
        </ExpansionPanel>
    );
};
