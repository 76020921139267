import React, { useState, useEffect } from 'react';
import GlobalStyle from './GlobalStyle';
// import * as Sentry from '@sentry/react';
import { AHUD } from '@swegon-core/ui-components';
import { Container, HideOnPrint } from './App.styles';
import {
    TranslationContext,
    Context as Translation
} from './modules/Translation/TranslationContext';
import { SettingsContext } from './modules/Settings/SettingsContext';
import { ApplicationContext } from './modules/Application/ApplicationContext';
import { Settings as SettingsWindow } from './components/Settings';
import { Importer } from './components/Importer';
import { Exporter } from './components/Exporter';
import { TabGroupWrapper } from './components/TabGroupWrapper';
import { Print as PrintComponent } from '../src/components/Print/Print';
import { UnitConversionContext } from './modules/Application/UnitConversionContext';

const App = ({
    lFromAhu,
    typeOfUnitSystemFromAhu,
    typeOfGraphFromAhu,
    typeOfHumidityFromAhu,
    internalApplicationData = null
}) => {
    const { Header, IconButton, Print, Settings } = AHUD;
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);
    const [isPrintVisible, setIsPrintVisible] = useState(false);
    const isExternalApplication = internalApplicationData === null;

    const toggleSettings = () => {
        setIsSettingsVisible(!isSettingsVisible);
    };

    const printClick = () => {
        if (typeof window === 'undefined') {
            return;
        }
        window.location.hash = 'print';
        setIsPrintVisible(true);
        // Timeout function is used to make chart and fonts to load properly
        setTimeout(function () {
            window.print();
        }, 250);
    };

    const afterPrint = () => {
        if (typeof window === 'undefined') {
            return;
        }
        window.location = window.location.href.split('#')[0];
        setIsPrintVisible(false);
    };

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }
        const mql = window.matchMedia('print');
        if (mql) {
            const handlePrint = () => !mql.matches && afterPrint();
            mql.addEventListener('change', handlePrint);
            return () => mql.removeEventListener('change', handlePrint);
        } else {
            window.onafterprint = afterPrint;
        }
    }, [window]);

    return (
        <div>
            {isExternalApplication && <GlobalStyle />}
            <SettingsContext
                isExternalApplication={isExternalApplication}
                typeOfUnitSystemFromAhu={typeOfUnitSystemFromAhu}
                typeOfGraphFromAhu={typeOfGraphFromAhu}
                typeOfHumidityFromAhu={typeOfHumidityFromAhu}
            >
                <UnitConversionContext>
                    <TranslationContext lFromAhu={lFromAhu}>
                        <ApplicationContext internalApplicationData={internalApplicationData}>
                            <PrintComponent isVisible={isPrintVisible} />
                            <HideOnPrint>
                                <Translation.Consumer>
                                    {({ l }) =>
                                        isExternalApplication && (
                                            <Header
                                                title={l('header.title')}
                                                subtitle={'v' + process.env.npm_package_version}
                                            >
                                                <Importer/>
                                                <Exporter/>
                                                <IconButton
                                                    onClick={() => printClick()}
                                                    tooltip={l('header.print')}
                                                    testid={'printButton'}
                                                >
                                                    <Print />
                                                </IconButton>
                                                <IconButton
                                                    onClick={toggleSettings}
                                                    tooltip={l('header.settings')}
                                                    testid={'settingsButton'}
                                                >
                                                    <Settings />
                                                </IconButton>
                                            </Header>
                                        )
                                    }
                                </Translation.Consumer>
                                <Container isExternalApplication={isExternalApplication}>
                                    <SettingsWindow
                                        isVisible={isSettingsVisible}
                                        close={toggleSettings}
                                    />
                                    <TabGroupWrapper />
                                </Container>
                            </HideOnPrint>
                        </ApplicationContext>
                    </TranslationContext>
                </UnitConversionContext>
            </SettingsContext>
        </div>
    );
};

export default App;
