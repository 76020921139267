import { WHITE } from '@swegon-core/ui-components';
import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 0.625rem 1fr 2.5rem;
    align-items: center;
    padding: 0 0.625rem;
    background-color: ${WHITE};
`;

export const RectangleContainer = styled.div`
    width: 0.1875rem;
    height: 3.125rem;
    padding-left: 0.1875rem;
`;

export const Data = styled.div`
    grid-column: 2;
    padding-left: 0.625rem;
`;
