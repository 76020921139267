import { v4 as uuidv4 } from 'uuid';
import { defaultPointValues } from "./defaultValues";
import { APPLICATION_VERSION } from '../../../lib/version';
import { SEASONS } from '../../../lib/constants'

export const createNewTab = (
    title,
    flowDataValues,
    tabsContent = null,
    isExternalApplication = true,
    season = SEASONS.SUMMER
) => {
    const id = uuidv4();
    return {
        id: id,
        title: title,
        version: APPLICATION_VERSION,
        updated: Date.now(),
        content: {
            points: tabsContent?.points ?? [],
            lines: tabsContent?.lines ?? [],
            lineToNext: true,
            isAddPointVisible: false,
            name: '',
            isEditing: false,
            flowDataValues: flowDataValues,
            pointValues: defaultPointValues(),
            season: season
        },
        isDraggable: isExternalApplication,
        tabType: isExternalApplication ? 'normal' : 'static'
    };
};

export const createTabsFromTabsContent = (tabsContent, flowDataValues, season, isExternalApplication = false) => {
    const tabs = [];
    tabsContent.forEach(tabContent => {
        const pointsAndLines =
            season === SEASONS.WINTER
                ? tabContent.winter.pointsAndLines
                : tabContent.summer.pointsAndLines;
        tabs.push(createNewTab(tabContent.name, flowDataValues, pointsAndLines, isExternalApplication, season));
    });
    return tabs;
};