import React, { createContext, useState, useEffect, useContext } from 'react';
// import * as Sentry from '@sentry/react';
import { GRAPHS, LANGUAGES, PRINT_ORIENTATIONS, UNIT_ID, UNIT_SYSTEM } from '../../lib/constants';

export const Context = createContext({});

const DEFAULT_LOCALE =
    typeof navigator !== 'undefined'
        ? (navigator.language || navigator.userLanguage || '').startsWith('sv')
            ? LANGUAGES.SE
            : LANGUAGES.EN
        : 'en-GB';
const DEFAULT_PRINT_ORIENTATION = PRINT_ORIENTATIONS.PORTRAIT;
const DEFAULT_TYPE_OF_UNITS = UNIT_SYSTEM.METRIC;
const DEFAULT_TYPE_OF_GRAPH = GRAPHS.MOLLIER;
const DEFAULT_TYPE_OF_HUMIDITY = UNIT_ID.relativeHumidity;

const DEFAULT_USER_AXIS_VALUES = {
    minMoisture: '',
    maxMoisture: '',
    minTemperature: '',
    maxTemperature: ''
};

export const SettingsContext = ({
    isExternalApplication,
    typeOfUnitSystemFromAhu,
    typeOfGraphFromAhu,
    typeOfHumidityFromAhu,
    children // Child Components
}) => {
    const [locale, setLocale] = useState('');
    const [printOrientation, setPrintOrientation] = useState(null);
    const [typeOfUnitSystem, setTypeOfUnitSystem] = useState(typeOfUnitSystemFromAhu ?? null);
    const [typeOfGraph, setTypeOfGraph] = useState(typeOfGraphFromAhu ?? null);
    const [typeOfHumidity, setTypeOfHumidity] = useState(typeOfHumidityFromAhu ?? null);
    const [userAxisValues, setUserAxisValues] = useState(null);
    const [visualUserAxisValues, setVisualUserAxisValues] = useState({});

    const setPrintOrientationAndReload = value => {
        if (typeof window === 'undefined') {
            return;
        }
        setPrintOrientation(value);
        window.location.reload();
    };

    const state = {
        locale,
        setLocale,
        printOrientation,
        setPrintOrientation: setPrintOrientationAndReload,
        typeOfUnitSystem,
        setTypeOfUnitSystem,
        typeOfGraph,
        setTypeOfGraph,
        typeOfHumidity,
        setTypeOfHumidity,
        userAxisValues,
        setUserAxisValues,
        visualUserAxisValues,
        setVisualUserAxisValues,
        isExternalApplication
    };

    const patchLocale = () => {
        if (!locale.length) {
            return;
        }
        const length = locale.length;
        const language = locale.slice(0, 2);
        const country = locale.slice(length - 2, length);

        // We have already migrated once
        if (country == country.toUpperCase()) {
            return;
        }

        const converted = `${language}-${country.toUpperCase()}`;
        setLocale(converted);
    };

    useEffect(() => {
        setTypeOfUnitSystem(typeOfUnitSystemFromAhu);
        setTypeOfGraph(typeOfGraphFromAhu);
        setTypeOfHumidity(typeOfHumidityFromAhu);
    }, [typeOfUnitSystemFromAhu, typeOfGraphFromAhu, typeOfHumidityFromAhu]);

    useEffect(() => {
        // Migrate lowercase to uppercase
        // TODO: remove this once it has been in production for a while
        patchLocale();
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (locale) {
            localStorage.setItem('locale', locale);
            // Sentry.setTag('language', locale);
        } else {
            setLocale(localStorage.getItem('locale') ?? DEFAULT_LOCALE);
        }
    }, [locale]);

    useEffect(() => {
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (printOrientation) {
            localStorage.setItem('print_orientation', printOrientation);
        } else {
            setPrintOrientation(
                localStorage.getItem('print_orientation') ?? DEFAULT_PRINT_ORIENTATION
            );
        }
    }, [printOrientation]);

    useEffect(() => {
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (typeOfUnitSystem) {
            localStorage.setItem('typeOfUnitSystem', typeOfUnitSystem);
        } else {
            setTypeOfUnitSystem(localStorage.getItem('typeOfUnitSystem') ?? DEFAULT_TYPE_OF_UNITS);
        }
    }, [typeOfUnitSystem]);

    useEffect(() => {
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (typeOfGraph) {
            localStorage.setItem('typeOfGraph', typeOfGraph);
        } else {
            setTypeOfGraph(localStorage.getItem('typeOfGraph') ?? DEFAULT_TYPE_OF_GRAPH);
        }
    }, [typeOfGraph]);

    useEffect(() => {
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (typeOfHumidity) {
            localStorage.setItem('typeOfHumidity', typeOfHumidity);
        } else {
            setTypeOfHumidity(localStorage.getItem('typeOfHumidity') ?? DEFAULT_TYPE_OF_HUMIDITY);
        }
    }, [typeOfHumidity]);

    useEffect(() => {
        if (!isExternalApplication || typeof window === 'undefined') {
            return;
        }
        if (userAxisValues) {
            localStorage.setItem('userAxisValues', JSON.stringify(userAxisValues));
        } else {
            setUserAxisValues(
                JSON.parse(localStorage.getItem('userAxisValues')) ?? DEFAULT_USER_AXIS_VALUES
            );
        }
    }, [userAxisValues]);

    return <Context.Provider value={state}>{children}</Context.Provider>;
};

export default SettingsContext;
