import styled from 'styled-components';

export const Container = styled.div`
    padding: 1vh 1vw;
    box-sizing: border-box;
    grid-row: span 3;
`;

export const Canvas = styled.canvas`
    width: '100%';
    height: '100%';
    padding-top: ${props => (props.id === 'print' ? '30px' : '0px')};
`;
